
import React, { useEffect, useState } from 'react';
import imageProfile from '../../assets/Images/users/altImage.png';
import API from '../../utils/API';
export default function UserStats() {
    const [userStats, setUserStats] = useState([]);
    const getUsers = async () => {
        try {
            const res = await API().getUsersDetails();
            if (res) {
                setUserStats(res.data?.Users)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div >
            <div>
                <h4>Total Users</h4>
                <p>{userStats.length - 1}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4>Users</h4>
                {userStats.map((element) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: "500px" }}>
                            <img
                                src={element?.Profile_picture !== "Null" ? element?.Profile_picture : imageProfile}
                                className="rounded-circle avatar-lg img-thumbnail align-self-center"
                                style={{ height: '50px', width: '50px' }}
                                alt="Steemit"
                            />
                            <p style={{ marginTop: '10px' }}>{element.Name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
