import React from "react";
import { Navigate, Route, Routes as SwitchRoute } from "react-router-dom";

import SignIn from "../Components/Authentiction/SignIn";
import About from "../Components/Pages/About";
import DashBoard from "../Components/Pages/dashboard";
import UserStats from "../Components/Pages/UserStats";

const AuthProtected = (props) => {
  /*
      Navigate is un-auth access protected routes via url
      */

  if (props.isAuthProtected && !localStorage?.getItem("authUser")) {
    return <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  }

  return <>{props.children}</>;
};

/**
 * Main Route component
 */
const Routes = () => {
  return (
    // rendering the router with layout
    <React.Fragment>
      <AuthProtected>
        <SwitchRoute>
          <Route path="/" element={<SignIn />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/about" element={<About />} />
          <Route path="/`userStats`" element={<UserStats />} />
        </SwitchRoute>
      </AuthProtected>
    </React.Fragment>
  );
};

export default Routes;
