import "./Components/assets/scss/themes.scss";
// import './bootstrap/css/bootstrap.min.css';
import "./Components/Styles/index.css";
import "./Components/assets/scss/themes.scss";
import React, { useEffect, useState, useMemo } from "react";
import SteemProvider from "react-steemlogin";
import { AppContext } from "./Components/StateManagement/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/index";
//SteemLogin Config JSON
import { io } from "socket.io-client";
import API from "./Components/utils/API";

export const socketConn = io(process.env.REACT_APP_API_URL, {
  reconnectionDelayMax: 2000,
  auth: {
    token: "123",
  },
});
const STEEM_CONFIG = {
  baseURL: "https://steemlogin.com/",
  app: "steem.dapps",
  callbackURL: process.env.REACT_APP_REDIRECT_URL,
  scope: [
    "login",
    "vote",
    "comment",
    "delete_comment",
    "comment_options",
    "custom_json",
  ],
};
const App = () => {
  const [userData, setUserData] = useState({});

  const [userDetails, setUserDetails] = useState({});
  const [steemKeychain, setSteemKeychain] = useState(null);
  const [socket, setSocket] = useState();
  const [loggedInId, setLoggedInId] = useState("");

  const Id = localStorage?.getItem("id") || "";

  const value = useMemo(
    () => ({
      userData,
      setUserData,
      userDetails,
      setUserDetails,
      socket,
      setSocket,
      steemKeychain,
      setSteemKeychain,
    }),
    [
      userData,
      setUserData,
      userDetails,
      setUserDetails,
      socket,
      setSocket,
      steemKeychain,
      setSteemKeychain,
    ]
  );
  useEffect(() => {
    setSocket(socketConn);
  }, []);

  const UpdateMessage = (data) => {
    if (!data) {
      console.error("Invalid data provided to UpdateMessage.");
      return;
    }
  

    const updateMessages = (details, identifierKey, identifierValue, newMessageKey) => {
      if (!details) {
        console.error("Details are undefined or null.");
        return;
      }
  
      const group = details.find((item) => item?.[identifierKey] === identifierValue);
  
      if (!group) {
        console.error("Group not found in details.");
        return;
      }
  
      const messages = group.messages || [];
  
      const typingMessages = messages.filter((msg) => msg?.isTyping);
      const textMessages = messages.filter((msg) => !msg?.isTyping);
  
      group.messages = [...textMessages, data?.[newMessageKey], ...typingMessages];
    };
  
    if (userData?.groups) {
      const groups = userData.groups;
  
      updateMessages(groups, "GroupName", data?.groupName, "messageToRoom");
  
      setUserData((prevState) => ({
        ...prevState,
        groups,
      }));
    } else if (userData?.recentChatList) {
      const recentChatList = userData.recentChatList;
  
      updateMessages(recentChatList, "_id", data?.groupName, "messageToUser");
  
      setUserData((prevState) => ({
        ...prevState,
        recentChatList,
      }));
    } else {
      console.error("No valid groups or recentChatList found in userData.");
    }
  };
  

  const updateUserTyping = (data, type) => {
    if (type === "Group") {
      let Details = userData?.groups;
      if (data.messageToRoom.isTypingStopped) {
        if (Details) {
          Details.filter((a) => a.name == data.groupName)[0].messages =
            Details.filter((a) => a.name == data.groupName)[0].messages.filter(
              (e) => {
                if (
                  e?.status === "3" &&
                  data?.messageToRoom?.userName === e?.userName &&
                  e?.isTyping === true
                ) {
                  return false;
                } else {
                  return true;
                }
              }
            );
          setUserData((prevState) => ({
            ...prevState,
            groups: Details,
          }));
        }
      } else {
        if (Details) {
          Details.filter((a) => a.name == data.groupName)[0].messages.push(
            data?.messageToRoom
          );
          setUserData((prevState) => ({ ...prevState, groups: Details }));
        }
      }
    } else if (type === "Peer") {
      let Details = userData?.recentChatList;
      if (data.messageToUser.isTypingStopped) {
        if (Details) {
          Details.filter((a) => a.chat_id == data.groupName)[0].messages =
            Details.filter(
              (a) => a.chat_id == data.groupName
            )[0].messages.filter((e) => {
              if (
                e?.status === "3" &&
                data?.messageToUser?.userName === e?.userName &&
                e?.isTyping === true
              ) {
                return false;
              } else {
                return true;
              }
            });
          Details.filter(
            (a) => a.chat_id == data.groupName
          )[0].isTyping = false;
          setUserData((prevState) => ({
            ...prevState,
            recentChatList: Details,
          }));
        }
      } else {
        if (Details) {
          Details.filter((a) => a.chat_id == data.groupName)[0].messages.push(
            data?.messageToUser
          );
          Details.filter((a) => a.chat_id == data.groupName)[0].isTyping = true;
          setUserData((prevState) => ({
            ...prevState,
            recentChatList: Details,
          }));
        }
      }
    }
  };

  // const UpdateMessagePersonal = (data) => {
  //   let Details = userData?.recentChatList;
  //   Details.filter((a) => a?._id == userData?.active_user)[0].messages =
  //     data?.data;
  //   setUserData((prevState) => ({ ...prevState, recentChatList: Details }));
  // };

  const [fetchingUserList, setFetchingUserList] = useState(false);
  const [recentChatList, setRecentChatList] = useState([]);
  // console.log("🚀 ~ App.js ~ recentChatList:", recentChatList);

  // ~ fetching user chat list
  useEffect(() => {
    if (Id && Id !== "") {
      setLoggedInId(Id);

      if (!recentChatList || recentChatList.length === 0) {
        if (socket) {
          // console.log("🚀 => Id:", Id);

          // console.log(
          //   "====>>>>> getting the user chat list data from the socket"
          // );
          socket.emit(
            "userRecentChatList",
            parseInt(localStorage?.getItem("id"))
          );
          socket.on("userRecentChatData", (data) => {
            // console.log(
            //   "🚀 => user recent chat list data from socket  :::",
            //   data || "no data for user chat list"
            // );

            if (data) {
              setRecentChatList(data.chatList);
              setUserData((prevState) => ({
                ...prevState,
                recentChatList: data.chatList,
              }));
            }
          });
        }
      }
      // ~ setting the getUser for socket
    }
  }, [socket]);

  // const getUserChatList = async () => {
  //   try {
  //     setFetchingUserList(true);
  //     const results = await API().getUserChatList(
  //       parseInt(localStorage?.getItem("id"))
  //     );
  //     if (results.data.status) {
  //       setRecentChatList(results.data.chatList);
  //       setUserData((prevState) => ({
  //         ...prevState,
  //         recentChatList: results.data.chatList,
  //       }));
  //     }
  //     console.log("🚀 => results:", results.data);
  //     socket?.emit("JoinPeerChats", parseInt(localStorage?.getItem("id")));
  //     setFetchingUserList(false);
  //   } catch (err) {
  //     console.log(err);
  //     setFetchingUserList(false);
  //   }
  // };

  // useEffect(() => {
  //   if (!recentChatList || recentChatList.length === 0) {
  //     getUserChatList();
  //   }
  // }, [recentChatList]);

  const UpdateUserStatus = (data, status) => {
    // console.log("🚀 => status:", status);
    // console.log("🚀 => data:", data);
    // console.log("===>>> => setting user status to onlineee :: ", data);
    let Details = recentChatList?.recentChatList;
    if (Details && Details?.filter((a) => a?.alt_user_id === data)) {
      Details.filter((a) => a.alt_user_id === data)[0].isConnected = status;
      setUserData((prevState) => ({ ...prevState, recentChatList: Details }));
    }
  };

  // useEffect(() => {
  //   UpdateUserStatus(Id);
  // }, [recentChatList]);

  useEffect(() => {
    if (socket) {
      //~ on connecting the socket
      socket.on("connect", () => {
        // console.log("user connected id : ", socket.id);
        if (localStorage?.getItem("id"))
          // console.log("id found in the local storage :: ");
          socket?.emit("JoinChats", parseInt(localStorage?.getItem("id"))); // x8WIv7-mJelg7on_ALbx

        socket.on("userOnline", (userId) => {
          // console.log("====>>>>>>>>>>User online, id:", userId);
          // Update UI to show user is online
          if (userId) {
            UpdateUserStatus(userId, { status: "online" });
          }
        });

        // ~ getting the online user list
        socket.on("onlineUsersList", (onlineUsersList) => {
          // console.log("🚀 => onlineUsersList:", onlineUsersList);
        });
      });

      // ~ on disconnecting the socket
      socket.on("disconnect", () => {
        console.log("user disconnected");

        // ~ setting the user status to offline
        socket.emit("userDisconnect", parseInt(Id));

        // UpdateUserStatus({ userId: parseInt(Id) }, { status: "offline" });
      });

      return () => {
        socket.off("connect");
        socket.off("userOnline");
        socket.off("disconnect");
        socket.off("onlineUsersList");
      };
    }
  }, [socket, userData]);

  // ~ sending the user id
  useEffect(() => {
    if (Id && Id !== "") {
      setLoggedInId(Id);

      // ~ setting the getUser for socket
      if (socket) {
        // console.log("🚀 => Id:", Id);

        socket.emit("getUser", parseInt(Id));
        // socket.emit("userConnect", parseInt(Id));
      }
    }
  }, [Id, socket]);

  // console.log("logged in user id is :: ", loggedInId);

  // ~ setting the online status
  const loggedInStatusId = localStorage?.getItem("userId") || "";
  useEffect(() => {
    if (loggedInStatusId && loggedInStatusId !== "") {
      // console.log("🚀 => loggedInStatusId:", loggedInStatusId);
      if (socket) {
        socket.emit("userConnect", parseInt(loggedInStatusId));
      }
    }
  }, [loggedInStatusId, socket]);

  useEffect(() => {
    if (socket) {
      // socket.on("LiveMessages", (data) => {
      //   console.log("🚀 ~ socket.on ~ data:", data)
      //   UpdateMessage(data);
      // });
      socket.on("LiveMessagesPeer", (data) => {

        UpdateMessage(data);
      });
      socket.on("isUserTyping", (data) => {
        updateUserTyping(data, "Group");
      });
      socket.on("isUserTypingPeer", (data) => {
        updateUserTyping(data, "Peer");
      });
      // socket.on("PeerMessageData", (data) => {
      //   UpdateMessagePersonal(data);
      // });
      socket.on("userOnline", (data) => {
        // console.log("🚀 => data:", data);
        if (data) {
          UpdateUserStatus(data, { status: "online" });
        }
      });

      return () => {
        socket.off("LiveMessages");
        socket.off("LiveMessagesPeer");
        socket.off("isUserTyping");
        socket.off("isUserTypingPeer");
        socket.off("PeerMessageData");
        socket.off("userOnline");
      };
    }
  }, [socket, userData]);

  return (
    <SteemProvider config={STEEM_CONFIG}>
      <AppContext.Provider value={value}>
        <Routes />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AppContext.Provider>
    </SteemProvider>
  );
};

export default App;
