import React, { useContext } from "react";

import { TabContent, TabPane } from "reactstrap";

//Import Components
import { AppContext } from "../../StateManagement/AppContext";
import Chats from "./Tabs/Chats";
import Contacts from "./Tabs/Contacts";
import Groups from "./Tabs/Groups";
import Profile from "./Tabs/Profile";
import Settings from "./Tabs/Settings";
function ChatLeftSidebar(props) {
  // console.log("🚀 => props:", props);
  const { userData } = useContext(AppContext);

  const activeTab = userData?.user?.activeTab
    ? userData?.user?.activeTab
    : "chat";
  // console.log(activeTab);

  // useEffect(() => {
  //   if (socketConn) {
  //     console.log("socket is connected");
  //     // ~ getting the online user list
  //     socketConn.on("onlineUsersList", (onlineUsersList) => {
  //       console.log("🚀 => onlineUsersList:", onlineUsersList);
  //     });
  //   }
  // }, [socketConn]);
  return (
    <React.Fragment>
      <div className="chat-leftsidebar me-lg-1">
        {activeTab && (
          <TabContent activeTab={activeTab}>
            {/* Start Profile tab-pane */}
            <TabPane tabId="profile" id="pills-user">
              {/* profile content  */}
              <Profile />
            </TabPane>
            {/* End Profile tab-pane  */}

            {/* Start chats tab-pane  */}
            <TabPane tabId="chat" id="pills-chat">
              {/* chats content */}
              <Chats recentChatList={props.userChat} />
            </TabPane>
            {/* End chats tab-pane */}

            {/* Start groups tab-pane */}
            <TabPane tabId="group" id="pills-groups">
              {/* Groups content */}
              <Groups props={props.userChat.groups} />
            </TabPane>
            {/* End groups tab-pane */}

            {/* Start contacts tab-pane */}
            <TabPane tabId="contacts" id="pills-contacts">
              {/* Contact content */}
              <Contacts />
            </TabPane>
            {/* End contacts tab-pane */}

            {/* Start settings tab-pane */}
            <TabPane tabId="settings" id="pills-setting">
              {/* Settings content */}
              <Settings />
            </TabPane>
            {/* End settings tab-pane */}
          </TabContent>
        )}
        {/* end tab content */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default ChatLeftSidebar;
// export default connect(mapStatetoProps, null)(ChatLeftSidebar);
