
import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

import './style.css'
export default function About() {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    return (
        <div className="text-center main">
            <div className="about-us container">
                <h1>About Us</h1>
                <h4>Welcome to Steemitchat!</h4>

                <p>
                    At Steemitchat, we're all about easy, secure, and fun communication.
                    With our user-friendly interface,<br />
                    top-notch privacy features, staying connected has never been
                    simpler.<br />
                    Join users of Steemit and experience messaging at its best with
                    Steemit Chat.
                </p>
                <div>
                    <div className="d-flex justify-content-evenly mt-2">
                        <img
                            className="rounded-circle ms-3"
                            src="https://steemitimages.com/u/blockseater/avatar"
                            width="80px"
                            height="80px"
                        />

                        <img
                            className="rounded-circle me-2"
                            src="https://steemitimages.com/u/rme/avatar"
                            width="80px"
                            height="80px"
                        />
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                        <a
                            className="py-0"
                            href="https://steemit.com/@blockseater"
                            target="_blank"
                        >
                            @blockseater
                        </a>

                        <a className="me-3" href="https://steemit.com/@rme" target="_blank"
                        >@rme</a>
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                        <strong className="ms-3">Core dev</strong>
                        <strong className="ms-2">Supporter</strong>
                    </div>

                    {/* <div className="d-flex justify-content-evenly mt-5">
                        <img
                            className="rounded-circle"
                            src="https://steemitimages.com/u/suboohi/avatar"
                            width="60px"
                            height="60px"
                        />

                        <img
                            className="rounded-circle"
                            src="https://steemitimages.com/u/malikusman1/avatar"
                            width="60px"
                            height="60px"
                        />

                        <img
                            className="rounded-circle ms-2"
                            src="https://steemitimages.com/u/steemdoctor1/avatar"
                            width="60px"
                            height="60px"
                        />

                        <img
                            className="rounded-circle"
                            src="https://steemitimages.com/u/ashkhan/avatar"
                            width="60px"
                            height="60px"
                        />
                    </div> */}
                    {/* <div className="d-flex justify-content-evenly mt-2">
                        <div className="ms-4">
                            <a className="" href="https://steemit.com/@suboohi" target="_blank"
                            >@Suboohi</a>
                        </div>
                        <div className="ms-3">
                            <a
                                className=""
                                href="https://steemit.com/@malikusman1"
                                target="_blank"
                            >@malikusman1</a>
                        </div>
                        <div className="">
                            <a
                                className=""
                                href="https://steemit.com/@steemdoctor1"
                                target="_blank"
                            >@steemdoctor1
                            </a>
                        </div>
                        <div className="">
                            <a
                                className="me-4"
                                href="https://steemit.com/@ashkhan"
                                target="_blank"
                            >@ashkhan</a>
                        </div>
                    </div> */}
                    {/* <div className="d-flex justify-content-evenly mt-2">
                        <div className="ms-2"><strong>steemchat ambassador</strong></div>
                        <div className="me-4"><strong>Witness Manager</strong></div>
                        <div className="me-3"><strong>Witness Manager</strong></div>
                        <div className="me-4"><strong>Witness Manager</strong></div>
                    </div> */}
                    <h4 className='mt-5' >
                        If you want to see more features Vote for witness
                        <a href="https://steemit.com/@stmpak.wit" target="_blank"
                        > @stmpak.wit</a>
                    </h4 >
                    <h4 className="mt-3">
                        Collaborator:
                        <a
                            className="text-decoration"
                            style={{}}
                            // "--bs-link-opacity: 0.7"
                            href="https://h4lab.com"
                            target="_blank"
                        > https://h4lab.com
                        </a>
                    </h4>
                </div>
            </div>
            <div className="FAQ container mt-5">
                <h1>FAQ's</h1>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">What is the app about?</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <div className="accordion-body">
                                A Real place for real user, This app brings decentralized
                                messaging only for steemit users. Free chat portal with info
                                tool.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">Is Steemkeychain required?</AccordionHeader>
                        <AccordionBody accordionId="2">
                            <div className="accordion-body">
                                Yes, Steem keychain is used for signing, encoding and decoding
                                messages sending it to steemit blockchain. It is not required
                                for Offchain chat. Steemlogin also avalible for Mobile users.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">How are messages authenticated?</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <div className="accordion-body">
                                Community chat is public chat only subscriber's can chat in it.
                                P2P message is signed by a posting key and verified both by the
                                backend nodes and by the frontend UI.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">Steemitchat features?</AccordionHeader>
                        <AccordionBody accordionId="4">
                            <div className="accordion-body">
                                Public community messaging. Private Onchain messaging All about
                                user info. All community's are available on steemitchat if user
                                already subscribed community's on steemmit. Read community rules
                                and discription. Private messaging via Custom_json method.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="5">Where are the message stored?</AccordionHeader>
                        <AccordionBody accordionId="5">
                            <div className="accordion-body">
                                The messages are stored on decentralized messaging backend
                                nodes.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>

            </div>
        </div>
    )
}
