import React, { useState, useEffect } from "react";
import {
  Input,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Spinner,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
// import OnlineUsers from "./OnlineUsers";
import { AppContext } from "../../../StateManagement/AppContext";
import { useContext } from "react";
// import { userChat } from "../../../GlobalVariables";
import API from "../../../utils/API";
import moment from "moment";
import ProfileModal from "../UserChat/ProfileModal";
import InAppLoading from "../../../LayOut/InAppLoading";
import { toast } from "react-toastify";

function Chats(props) {
  const { userData, setUserData, userDetails, socket, steemKeychain } =
    useContext(AppContext);
  const [searchChat, setSearchChat] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchedUserList, setSearchedUserList] = useState([]);
  const [isLoadingUsers, setisLoadingUser] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [recentChatList, setRecentChatList] = useState([]);
  const [ProfileModalOpen, setProfileModalOpen] = useState(false);
  const [ProfileModalLoading, setProfileModalLoading] = useState(false);
  const [fetchingUserList, setFetchingUserList] = useState(false);
  const [ProfileDetails, setProfileDetails] = useState({});

  const getUserChatList = () => {
    if (socket) {
      socket.emit("userRecentChatList", localStorage?.getItem("id")?.toString());
      socket.on("userRecentChatData", (data) => {
        if (data) {
          setRecentChatList(data.chatList);
        }
      });
    }
  };

  useEffect(() => {
    getUserChatList();
    var li = document.getElementById(
      "conversation" + props.recentChatList.active_user
    );
    if (li) {
      li.classList.add("active");
    }
  }, [socket]);

  function openUserChat(e, chat) {
    e.preventDefault();

    var chatId = chat._id;
    props.recentChatList.active_user = chatId;
    setUserData((prevState) => ({
      ...prevState,
      active_user: chatId,
      isGroup: false,
    }));

    var chatList = document.getElementById("chat-list");
    var clickedItem = e.target;
    var currentli = null;

    if (chatList) {
      var li = chatList.getElementsByTagName("li");
      //remove coversation user
      for (var i = 0; i < li.length; ++i) {
        if (li[i].classList.contains("active")) {
          li[i].classList.remove("active");
        }
      }
      //find clicked coversation user
      for (var k = 0; k < li.length; ++k) {
        if (li[k].contains(clickedItem)) {
          currentli = li[k];
          break;
        }
      }
    }

    //activation of clicked coversation user
    if (currentli) {
      currentli.classList.add("active");
    }

    var userChat = document.getElementsByClassName("user-chat");
    if (userChat) {
      userChat[0].classList.add("user-chat-show");
    }

    //removes unread badge if user clicks
    var unread = document.getElementById("unRead" + chat._id);
    if (unread) {
      unread.style.display = "none";
    }
  }

  const searchUsers = async (username) => {
    if (username) {
      setisLoadingUser(true);
      try {
        const results = await API().searchUsers(username);
        if (results?.data?.data) setSearchedUserList(results?.data?.data);
        getUserChatList();
      } catch (err) {
        console.log(err);
      } finally {
        setisLoadingUser(false);
      }
    } else {
      setSearchedUserList([]);
    }
  };

  useEffect(() => {
    const delayFn = setTimeout(() => searchUsers(searchChat), 1000);

    return () => clearTimeout(delayFn);
  }, [searchChat]);

  function isJson(data) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  function onCloseSearch() {
    setIsSearching(false);
    setSearchedUserList([]);
    setSearchChat("");
  }

  function openAddModal(data) {
    setUserSelected(data);
    setAddModal(true);
  }

  const openProfile = async (chat) => {
    try {
      setProfileModalLoading(true);
      const results = await API().GetUserdata(chat);
      setProfileDetails({
        ...results.data.data,
        profile_image: results.data.data.posting_json_metadata
          ? JSON.parse(results.data.data.posting_json_metadata).profile
              .profile_image
          : "",
      });
      setProfileModalLoading(false);
      setProfileModalOpen(true);
    } catch (err) {
      console.log(err);
      setProfileModalLoading(false);
    }
  };

  const toggleProfileModel = () => {
    setProfileModalOpen(!ProfileModalOpen);
  };

  const AddUser = async () => {
    try {
      setAddModal(false);
      setisLoadingUser(true);
      const res = await API().UpdateUser(userSelected);
      const results = await API().addUserChat(
        userSelected,
        parseInt(localStorage?.getItem("id")),
        res.data.userId
      );
      getUserChatList();
      onCloseSearch();
      setisLoadingUser(false);
      toast.success("User Added");
    } catch (err) {
      console.log(err);
      setisLoadingUser(false);
      toast.error("Unable to add user");
    } finally {
      setisLoadingUser(false);
    }
  };

  const handleRemoveChat = async (chat) => {
    try {
      setisLoadingUser(true);
      const res = await API().UpdateUser(userSelected);
      const results = await API().deleteChat(  chat?._id);
      getUserChatList();
      onCloseSearch();
      setisLoadingUser(false);
      toast.success("User Removed");
    } catch (err) {
      console.log(err);
      setisLoadingUser(false);
      toast.error("Unable to remove user");
    } finally {
      setisLoadingUser(false);
    }
  };
  return (
    <React.Fragment>
      <div>
        <InAppLoading isopened={ProfileModalLoading} />
        <ProfileModal
          isopened={ProfileModalOpen}
          toggleModel={toggleProfileModel}
          details={ProfileDetails}
          centered
          scrollable
        />
        <Modal isOpen={addModal} centered={true}>
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ModalBody>
              Are you sure to start chat with {userSelected?.name} ?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  AddUser();
                }}
              >
                Accept
              </Button>{" "}
              <Button color="secondary" onClick={() => setAddModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalBody>
        </Modal>
        <div className="p-4">
          {!isSearching && (
            <div className="user-chat-nav float-end">
              <div id="create-group">
                <Button
                  onClick={() => setIsSearching(true)}
                  type="button"
                  color="link"
                  className="text-decoration-none text-muted font-size-18 py-0"
                >
                  <i className="ri-search-line search-icon font-size-18"></i>
                </Button>
              </div>
              <UncontrolledTooltip target="create-group" placement="top">
                Search Users
              </UncontrolledTooltip>
            </div>
          )}
          <h4 className="mb-0">Chats</h4>
        </div>
        {isSearching ? (
          <>
            <div className="px-4 pt-0">
              <div className="search-box chat-search-box">
                <InputGroup size="lg" className="mb-3 rounded-lg">
                  <span
                    className="input-group-text text-muted bg-light pe-1 ps-3"
                    id="basic-addon1"
                  >
                    <i className="ri-search-line search-icon font-size-18"></i>
                  </span>
                  <Input
                    type="text"
                    value={searchChat}
                    onChange={(e) => setSearchChat(e.target.value)}
                    className="form-control bg-light"
                    placeholder="Search users"
                  />
                  <span
                    className="input-group-text text-muted bg-light pe-2"
                    id="basic-addon1"
                    onClick={() => setIsSearching(false)}
                  >
                    {/* <i className="ri-close-line close-line font-size-18"></i> */}
                    <Button
                      onClick={() => onCloseSearch()}
                      type="button"
                      color="link"
                      className="text-decoration-none text-muted font-size-18 py-0"
                    >
                      <i className="ri-close-line close-line font-size-18"></i>
                    </Button>
                  </span>
                </InputGroup>
              </div>
            </div>
            <div className="px-2">
              <h5 className="mb-1 px-3 font-size-16">Users</h5>
              <SimpleBar
                style={{ maxHeight: "100%" }}
                className="chat-message-list"
              >
                {isLoadingUsers ? (
                  <div className="d-flex w-100 justify-content-center align-items-center flex-column gap-2">
                    <Spinner>Loading...</Spinner>
                    Searching Users
                  </div>
                ) : (
                  <ul
                    className="list-unstyled chat-list chat-user-list"
                    id="chat-list"
                  >
                    {searchedUserList.length === 0 ? (
                      <div className="d-flex w-100 justify-content-center align-items-center">
                        <h5 className="px-3 mt-2 font-size-14">
                          No Users Available
                        </h5>
                      </div>
                    ) : (
                      searchedUserList?.map((user, key) => {
                        return (
                          <li
                            key={key}
                            id={"conversation" + key}
                            className={""}
                          >
                            <Link
                              to="#"
                              style={{ cursor: "context-menu" }}
                              // openUserChat(e, chat)
                            >
                              <div className="d-flex">
                                {!user?.posting_json_metadata &&
                                !isJson(user?.posting_json_metadata) ? (
                                  <div
                                    className={
                                      "chat-user-img align-self-center me-3 ms-0"
                                    }
                                  >
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                        {user.name.charAt(0)}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      "chat-user-img align-self-center me-3 ms-0"
                                    }
                                  >
                                    <img
                                      src={
                                        JSON.parse(user?.posting_json_metadata)
                                          ?.profile?.profile_image
                                      }
                                      className="rounded-circle avatar-xs"
                                      alt="Steemit"
                                    />
                                  </div>
                                )}

                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate font-size-15 mb-1">
                                    {user?.name}
                                  </h5>
                                  <p className="chat-user-message text-truncate mb-0">
                                    {moment(user?.created)?.format("lll")}
                                  </p>
                                </div>
                                {/* <div className="font-size-11"></div> */}

                                <i
                                  className="ri-information-line search-icon font-size-20"
                                  style={{
                                    cursor: "pointer",
                                    marginTop: -10,
                                    marginRight: 5,
                                  }}
                                  onClick={() => openProfile(user?.name)}
                                ></i>
                                {userDetails?.name !== user?.name && (
                                  <>
                                    {recentChatList?.filter((list) => {
                                      return list?.name === user?.name;
                                    }).length ? (
                                      <div className="unread-message">
                                        <span
                                          className="badge badge-soft-info rounded-pill"
                                          style={{ fontSize: "0.8rem" }}
                                        >
                                          Friend
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="unread-message">
                                        <span
                                          className="badge badge-soft-primary rounded-pill"
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "0.8rem",
                                          }}
                                          onClick={() => {
                                            openAddModal(user);
                                          }}
                                        >
                                          Start Chat
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    )}
                  </ul>
                )}
              </SimpleBar>
            </div>
          </>
        ) : (
          <div className="px-2">
            <h5 className="mb-3 px-3 font-size-16">Recent</h5>
            {fetchingUserList ? (
              <div className="d-flex w-100 justify-content-center align-items-center flex-column gap-2">
                <Spinner>Loading...</Spinner>
                Fetching Users ...
              </div>
            ) : (
              <SimpleBar
                style={{ maxHeight: "100%" }}
                className="chat-message-list"
              >
                {
                  // false
                  steemKeychain == null ? (
                    <div className="d-flex w-100 justify-content-center align-items-center gap-4">
                      <h5 className="pl-3 mt-3 font-size-14">
                        Keychain not connected ! <br /> download Keychain from{" "}
                        <a
                          href="https://chromewebstore.google.com/detail/steemkeychain/jhgnbkkipaallpehbohjmkbjofjdmeid?pli=1"
                          target="_blank"
                        >
                          Link
                        </a>
                        <br />
                        <br /> if Keychain already added to chrome,
                        <br /> please refresh the page
                        <br />
                        <br />
                        <Button
                          className="py-0 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <i className="ri-refresh-line search-icon font-size-17 mt-1 d-flex flex-column"></i>
                        </Button>
                      </h5>
                    </div>
                  ) : recentChatList?.length === 0 ? (
                    <div className="d-flex w-100 justify-content-center align-items-center gap-2">
                      <h5 className="pl-3 mt-3 font-size-18">
                        Add user to start chat{" "}
                      </h5>
                      <i className="ri-chat-smile-2-fill search-icon font-size-20 mt-1"></i>
                    </div>
                  ) : (
                    <ul
                      className="list-unstyled chat-list chat-user-list"
                      id="chat-list"
                    >
                      {recentChatList?.map((chat, key) => {
                        return (
                          <li
                            key={key}
                            id={"conversation" + key}
                            className={
                              chat.unRead.length > 0
                                ? "unread"
                                : chat?.isTyping
                                ? "typing"
                                : key === props.recentChatList.active_user
                                ? "active"
                                : ""
                            }
                          >
                            <Link to="#" onClick={(e) => openUserChat(e, chat)}>
                              <div className="d-flex">
                                {chat?.user_detail[0]?.profile_picture ===
                                "" ? (
                                  <div
                                    className={
                                      "chat-user-img " +
                                      chat?.user_detail[0]?.isConnected +
                                      " align-self-center me-3 ms-0"
                                    }
                                  >
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                        {chat?.user_detail[0].Name.charAt(0)}
                                      </span>
                                    </div>
                                    {chat?.user_detail[0]?.isConnected && (
                                      <span className="user-status"></span>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      "chat-user-img " +
                                      chat?.user_detail[0]?.isConnected +
                                      " align-self-center me-3 ms-0"
                                    }
                                  >
                                    <img
                                      src={
                                        chat?.user_detail[0]?.profile_picture
                                      }
                                      className="rounded-circle avatar-xs"
                                      alt="Steemit"
                                    />
                                    {/* user status dot will show here */}
                                    {chat?.user_detail[0]?.isConnected && (
                                      <span className="user-status"></span>
                                    )}
                                  </div>
                                )}

                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate font-size-15 mb-1">
                                    {chat?.user_detail[0]?.Name}
                                  </h5>
                                  {/* <p className="chat-user-message text-truncate mb-0">
                                    {chat.isTyping ? (
                                      <>
                                        typing
                                        <span className="animate-typing">
                                          <span className="dot ms-1"></span>
                                          <span className="dot ms-1"></span>
                                          <span className="dot ms-1"></span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {chat.messages &&
                                        chat.messages.length > 0 &&
                                        chat.messages[chat.messages.length - 1]
                                          .isImageMessage === true ? (
                                          <i className="ri-image-fill align-middle me-1"></i>
                                        ) : null}
                                        {chat.messages &&
                                        chat.messages.length > 0 &&
                                        chat.messages[chat.messages.length - 1]
                                          .isFileMessage === true ? (
                                          <i className="ri-file-text-fill align-middle me-1"></i>
                                        ) : null}
                                        {chat.messages &&
                                        chat.messages.length > 0
                                          ? chat.messages[
                                              chat.messages.length - 1
                                            ].message
                                          : null}
                                      </>
                                    )}
                                  </p> */}
                                  <div className="font-size-11">
                                    {chat.messages && chat.messages.length > 0
                                      ? chat.isTyping
                                        ? chat.messages[
                                            chat.messages.length - 2
                                          ]?.time || null
                                        : chat.messages[
                                            chat.messages.length - 1
                                          ]?.time || null
                                      : null}
                                  </div>
                                </div>
                                <div className="font-size-11 removeUser">
                                  <p
                                    className="d-flex flex-column"
                                    onClick={() => handleRemoveChat(chat)}
                                  >
                                    x
                                  </p>
                                </div>
                                {chat.unRead === 0 ? null : (
                                  <div
                                    className="unread-message"
                                    id={"unRead" + chat._id}
                                  >
                                    <span className="badge badge-soft-danger rounded-pill">
                                      {chat.messages && chat.messages.length > 0
                                        ? chat.unRead >= 20
                                          ? chat.unRead + "+"
                                          : chat.unRead
                                        : ""}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )
                }
              </SimpleBar>
            )}
          </div>
        )}
        {/* End chat-message-list */}
      </div>
    </React.Fragment>
  );
}

export default Chats;

// export default connect(mapStateToProps, { setconversationNameInOpenChat, activeUser })(Chats);
