import axios from "axios";
// TOKEN :  5Hv82z1VtGb9q1yegzrv6aC6AEkwjSKzkQ81HySjbfMQPpzZmRi
// const Api = "http://localhost:3001"; // SYSTEM ACCESS IP
const Api = 'https://nizamuddintai-001-site11.ctempurl.com/'; // SYSTEM ACCESS IP
const steemApiUrl = "https://sds.steemworld.org";
const fetchJSONwithouttoken = async ({ url }) => {
  const res = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return axios.get(url);
};
const fetchJSONPOSTwithbodywithouttoken = async ({ url, body }) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return axios.post(url, JSON.stringify(body), axiosConfig);
};

const API = (credentials) => ({
  // ############################
  // ######## Get Apies #########
  // ############################

  GetUserCommunitites: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunitiesBySubscriber/${userName}`,
    }),
  GetUserCommunititesRoles: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunityRoles/${userName}`,
    }),
  GetUserCommunititesSubscribers: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunitySubscribers/${userName}`,
    }),
  GetUserdata: (userName) =>
    fetchJSONwithouttoken({
      url: `${Api}/getSteemAccount?name=${userName}`,
    }),
  searchUsers: (userName) =>
    fetchJSONwithouttoken({
      url: `${Api}/searchUsers?name=${userName}`,
    }),
  GetCommunititesDetails: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunity/${name}`,
    }),
  GetProfileDetails: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/accounts_api/getAccount/${name}`,
    }),
  GetProfileDelegatorsIncoming: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/delegations_api/getIncomingDelegations/${name}`,
    }),
  GetProfileDelegatorsOutGoing: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/delegations_api/getOutgoingDelegations/${name}`,
    }),
  GetCommunityByName: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunity/${name}`,
    }),
  GetCommunititesData: (id) =>
    fetchJSONwithouttoken({
      url: `${Api}/GetCommunititesData?userId=${id}`,
    }),
  getUserChatList: (id) =>
    fetchJSONwithouttoken({
      url: `${Api}/getUserChatList?userId=${id}`,
    }),
  // ############################
  // ######## Post Apies ########
  // ############################

  UpdateUser: (_details) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/UpdateUser`,
      body: {
        UserDetails: _details,
      },
    }),
  UpdateCommunities: (_details, _userid) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/UpdateCommunities`,
      body: {
        Details: _details,
        userId: _userid,
      },
    }),
});

export default API;
