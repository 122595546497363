import React, { useContext, useEffect, useState } from "react";
import { withSteem } from "react-steemlogin"; //CONSUMER HOC
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Alert,
  Form,
  Input,
  Button,
  FormFeedback,
  Label,
  InputGroup,
} from "reactstrap";
import SteemLogo from "../assets/Images/Steemit-logo.png";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../StateManagement/AppContext";
import LoadingScreen from "../LayOut/LoadingScreen";
import API from '../utils/API'
const SignIn = withSteem(({ steem }) => {
  const navigate = useNavigate();
  const { loginUrl, auth, loading } = steem;
  const { userDetails, setUserDetails, socket } = useContext(AppContext);
  const [LoadKeychain, setLoadKeychain] = useState(false);
  const [userName, setUserName] = useState("");
  const checkState = async () => {
    if (auth || JSON.stringify(userDetails) != "{}") {
      setUserDetails(auth || userDetails);
      try {
        const res = await API().UpdateUser(auth || userDetails);
        localStorage.setItem('userId', res.data.userId)
        socket?.emit("userConnect", res.data.userId)
      } catch (err) {
        console.log(err)
      }
      navigate("/dashboard");
    }
  }
  useEffect(() => {
    checkState()
  }, [auth]);

  const LoginKeyChain = () => {
    if (userName) {
      if (window.steem_keychain) {
        setLoadKeychain(true)
        const steem_keychain = window.steem_keychain;
        // Steem Keychain extension installed...
        // console.log(steem_keychain);
        steem_keychain.requestHandshake(function () {
          steem_keychain.requestSignBuffer(
            userName,
            "User Verification",
            "Posting",
            async function (response) {
              if (response.success) {
                const res = await fetch(`https://nizamuddintai-001-site11.ctempurl.com/getSteemAccount?name=${response.data.username}`);
                const userDets = await res.json();
                if (userDets.status) {
                  setUserDetails(userDets.data);
                  // auth = userDets.data; 
                  try {
                    const res2 = await API().UpdateUser(auth || userDetails);
                    localStorage.setItem('userId', res2.data.userId)
                  } catch (err) {
                    console.log(err)
                  }
                  navigate("/dashboard");
                  setLoadKeychain(false)
                }
              } else {
                toast.warn("User Verification Failed!", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }

              setLoadKeychain(false)
              // console.log(response);
            }
          );
        });
      } else {
        toast.warn("Steemit Keychain Extension Not Installed !", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.warn("Enter User Name !", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  if (loading || LoadKeychain) return <LoadingScreen />;
  return (
    // <div className="account-pages" style={{ background: "#EDF3F9" }}>
    //   <Container>
    //     <Row className="justify-content-center">
    //       <Col md={8} lg={6} xl={5}>
    //         <div className="text-center mb-2">
    //           <a href="/" className="auth-logo mb-2 d-block my-2 pt-sm-5">
    //             <img
    //               src={SteemLogo}
    //               alt=""
    //               height="100"
    //               className="logo logo-dark"
    //             />
    //           </a>

    //           <h4>Sign in</h4>
    //           <p className="text-muted mb-4">
    //             Sign in to continue to end-to-end encrypted Steemit Chat.
    //           </p>
    //         </div>
    //         <div className="emboss">
    //           {/* <Card >
    //             <CardBody className="p-4"> */}
    //           {/* {
    //               props.error && <Alert color="danger">{props.error}</Alert>
    //             } */}
    //           <div className="p-2 d-flex justify-content-center align-items-center">
    //             <Form>
    //               <div className="mb-3">
    //                 <Label className="form-label">Steem ID</Label>
    //                 <InputGroup className="mb-3 bg-soft-light rounded-3">
    //                   <span
    //                     className="input-group-text text-muted"
    //                     id="basic-addon3"
    //                   >
    //                     <AiOutlineUser />
    //                   </span>
    //                   <Input
    //                     type="text"
    //                     id="Steem ID"
    //                     name="Steem ID"
    //                     className="form-control form-control-lg border-light bg-soft-light"
    //                     placeholder="Enter Steem ID"
    //                     onChange={(e) => setUserName(e.target.value)}
    //                     value={userName}
    //                   />
    //                 </InputGroup>
    //               </div>

    //               <div className="d-grid">
    //                 <Button
    //                   color="primary"
    //                   block
    //                   className=" waves-effect waves-light"
    //                   onClick={() => LoginKeyChain()}
    //                 >
    //                   Keychain Login
    //                 </Button>
    //               </div>
    //               <div className="d-grid mt-1 d-flex flex-column">
    //                 <h5 className="mt-1 d-inline-flex align-self-center">
    //                   -- OR --
    //                 </h5>
    //                 {/* <a href={loginUrl}> */}
    //                 <Button
    //                   color="primary"
    //                   block
    //                   className=" waves-effect waves-light"
    //                   onClick={() => window.open(loginUrl, "_self")}
    //                 >
    //                   Try Steem Login
    //                 </Button>
    //                 {/* </a> */}
    //               </div>
    //             </Form>
    //           </div>
    //           {/* </CardBody>
    //           </Card> */}
    //         </div>

    //         <div className="mt-5 text-center">
    //           <p>
    //             Don't have an account ?{" "}
    //             <a
    //               href="https://steemyy.com/reg.php"
    //               className="font-weight-medium text-primary"
    //             >
    //               {" "}
    //               Signup now{" "}
    //             </a>{" "}
    //           </p>
    //           <p>
    //             © {new Date().getFullYear()} Developed with{" "}
    //             <i className="mdi mdi-heart text-danger"></i> by @stmpak.wit
    //             witness
    //           </p>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
        <div className="container">
      <h1>New Version Coming Soon!</h1>
      <p>
        SteemChat is currently undergoing maintenance as we work hard to bring
        you new and exciting features. We apologize for any inconvenience and
        appreciate your patience.
      </p>
      <br></br>
      <p>Thank you for your understanding and support.</p>
      <br></br>
    </div>
  );
});

export default SignIn;


// function updateManaBar() {
//   // Fetch account information from the Steem blockchain
//   steem.api.getAccounts([username], function (err, result) {
//     if (!err && result && result.length > 0) {
//       const account = result[0];
//       const votingPower = account.voting_power;
//       const maxVotingPower = 10000; // Maximum voting power on Steemit

//       // Calculate the percentage of voting power remaining
//       const percentage = (votingPower / maxVotingPower) * 100;

//       // Update the mana bar width and text
//       manaBar.style.width = `${percentage}%`;
//       manaBar.textContent = `Voting Power: ${percentage.toFixed(2)}%`;
//     } else {
//       console.error(err);
//     }
//   });
// }
// converted into percentage const part = 5570808436884;
// const whole = /* your whole value */;

// const percentage = (part / whole) * 100;
// console.log(`Percentage: ${percentage.toFixed(2)}%`);

// `raw = 99324188663068
// neg = raw < 0
// level = Math.log10(raw.to_i.abs)
// level = [level - 9, 0].max
// level = (neg ? -1 : 1) * level
// level = (level * 9) + 25`