import React, { useState, useEffect, useRef } from "react";
import {
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    Button,
    ModalFooter,
} from "reactstrap";
import { connect } from "react-redux";

import SimpleBar from "simplebar-react";

import withRouter from "../../../chat-components/withRouter";

//Import Components
import UserProfileSidebar from "../../../chat-components/UserProfileSidebar";
import SelectContact from "../../../chat-components/SelectContact";
import UserHead from "./UserHead";
import ImageList from "./ImageList";
import ChatInput from "./ChatInput";
import FileList from "./FileList";

//actions
// import { openUserSidebar, setFullUser } from "../../../redux/actions";
import { userChat as userChats, users } from "../../../GlobalVariables";
//Import Images
import avatar4 from "../../../assets/Images/users/avatar-4.jpg";
import avatar1 from "../../../assets/Images/users/avatar-1.jpg";

//i18n
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "../../../StateManagement/AppContext";

import { BsCheck2, BsCheck2All } from "react-icons/bs";
import moment from "moment";
import imageProfile from '../../../assets/Images/users/altImage.png';
import ProfileModal from "./ProfileModal";
import API from "../../../utils/API";
import InAppLoading from "../../../LayOut/InAppLoading";
import { BsFillReplyFill } from "react-icons/bs";

import SteemLogo from "../../../assets/Images/Steemit-logo.png";


function UserChat(props) {
    // console.log(props);
    props = props.recentChatList;
    const ref = useRef();
    const { userData, setUserData, userDetails, socket } = useContext(AppContext);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentGroup, setCurrentGroup] = useState({});
    const [reply, setReply] = useState(null);
    const [profileImg, setprofileImg] = useState(userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image : '');
    /* intilize t variable for multi language implementation */
    const [isToday, setIsToday] = useState(false)
    const [ProfileModalOpen, setProfileModalOpen] = useState(false);
    const [ProfileModalLoading, setProfileModalLoading] = useState(false);
    const [ProfileDetails, setProfileDetails] = useState({});
    const { t } = useTranslation();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /\B(@\*[\w.-]+\*)/g;

    const combinedRegex = /(https?:\/\/[^\s]+)|(@?\*[\w.-]+\*)/g;

    //demo conversation messages
    //userType must be required
    const [allUsers] = useState(
        JSON.stringify(userData).length == 2
            ? userChats.recentChatList
            : userData.recentChatList
    );
    const [chatMessages, setchatMessages] = useState([]);

    useEffect(() => {
        if (userData?.isGroup == true) {
            setCurrentGroup(
                userData?.groups?.filter((a) => a.groupId == userData.active_group)
            );
            setchatMessages(
                userData?.groups?.filter((a) => a.groupId == userData.active_group)[0]
                    ?.messages
            );
        } else {
            // console.log("user");
            if (userData?.recentChatList) {
                // console.log(userData?.recentChatList[userData?.active_user]?.messages, "DDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
                setchatMessages(
                    userData?.recentChatList[userData?.active_user]?.messages
                );
            }
        }
        // setchatMessages((userData?.isGroup == true?:));
        // console.log("chat call",userData,chatMessages);
        ref?.current?.recalculate();
        if (ref?.current?.el) {
            setTimeout(() => {
                // console.log(ref.current.getScrollElement().scrollTop,ref.current.getScrollElement().scrollHeight);
                ref.current.getScrollElement().scrollTop =
                    ref.current.getScrollElement().scrollHeight;
            }, 200);
        }
    }, [userData]);

    const toggle = () => setModal(!modal);

    const addMessage = (message, type, doc) => {
        let messageObj = null;
        //matches the message type is text, file or image, and create object according to it
        switch (type) {
            case "textMessage":
                messageObj = {
                    id: chatMessages.length + 1,
                    message: message,
                    time: moment(new Date()).format('DD/MM/YY hh:mm a'),
                    userType: "sender",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    isFileMessage: false,
                    isImageMessage: false, isMentioned: true
                };
                break;
            case "replyMessage":
                messageObj = {
                    id: chatMessages.length + 1,
                    message: message,
                    time: moment(new Date()).format('DD/MM/YY hh:mm a'),
                    userType: "sender",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    isFileMessage: false,
                    isImageMessage: false,
                    isReply: true,
                    replydetails: doc
                };
                break;
            case "fileMessage":
                messageObj = {
                    id: chatMessages.length + 1,
                    message: message,
                    fileMessage: doc.name,
                    size: doc.size,
                    time: moment(new Date()).format('DD/MM/YY hh:mm a'),
                    userType: "sender",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    image: avatar4,
                    isFileMessage: true,
                    isImageMessage: false,
                };
                break;
            case "imageMessage":
                messageObj = {
                    id: chatMessages.length + 1,
                    message: message,
                    imageMessage: [{ image: doc }],
                    size: doc.size,
                    time: moment(new Date()).format('DD/MM/YY hh:mm a'),
                    userType: "sender",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    image: avatar4,
                    isImageMessage: true,
                    isFileMessage: false,
                };
                break;
            default:
                break;
        }
        //add message object to chat

        setchatMessages([...chatMessages, messageObj]);

        if (userData?.isGroup) {
            let Details = userData
            Details.groups.filter(
                (a) => a.groupId == userData.active_group
            )[0].messages = chatMessages;
            setUserData(Details)
            const messageToRoom = {
                ...messageObj,
                userType: 'reciever',
            }
            socket?.emit("sendMessage", {
                groupName: Details.groups.filter(
                    (a) => a.groupId == userData.active_group
                )[0].name, messageToRoom
            })
        } else {
            userData.recentChatList[userData.active_user].messages = chatMessages;
            // setUserData(userData);
        }
        // userChats.setFullUser(copyallUsers);
        setTimeout(() => {
            scrolltoBottom();
        }, 0);
    };

    function scrolltoBottom() {
        if (ref.current.el) {
            ref.current.getScrollElement().scrollTop =
                ref.current.getScrollElement().scrollHeight + 100;
        }
    }

    const deleteMessage = (id) => {
        // let conversation = chatMessages;
        // var filtered = conversation.filter(function (item) {
        //   return item.id !== id;
        // });
        // setchatMessages(filtered);
    };

    const openProfile = async (chat) => {
        try {
            setProfileModalLoading(true)
            const results = await API().GetUserdata(chat.userName);
            setProfileDetails({
                ...results.data.data,
                profile_image: results.data.data.posting_json_metadata ? JSON.parse(results.data.data.posting_json_metadata).profile.profile_image : '',
            })
            setProfileModalLoading(false)
            setProfileModalOpen(true)
        } catch (err) {
            console.log(err)
            setProfileModalLoading(false)
        }

    }

    const toggleModel = () => {
        setProfileModalOpen(!ProfileModalOpen)
    };
    const removeReply = () => {
        setReply(null)
    };



    return (
        <React.Fragment>
            <div className="user-chat w-100"  >
                <InAppLoading isopened={ProfileModalLoading} />
                <ProfileModal isopened={ProfileModalOpen} toggleModel={toggleModel} details={ProfileDetails} centered scrollable />
                <div className="d-lg-flex" style={{ maxHeight: '100vh' }}>
                    <div className={userData.userSidebar ? "w-70" : "w-100"}>
                        {!userData?.active_group ? (
                            <div className='d-flex justify-content-center align-items-center gap-2 flex-column' style={{ height: '100vh' }}>
                                <img
                                    src={SteemLogo}
                                    alt=""
                                    height="100"
                                    className="logo logo-dark"
                                />
                                <h5 className="pl-3 mt-3 font-size-18">Welcome to Steem Chat</h5>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    <i className="ri-lock-password-fill search-icon font-size-20 mt-2"></i>
                                    <h5 className="pl-3 mt-3 font-size-14">Your personal messages are end-to-end encrypted</h5>
                                </div>
                            </div>) :
                            <>
                                <UserHead
                                    recentChatList={userData?.isGroup == true ? currentGroup : props}
                                />
                                <SimpleBar
                                    style={{ height: "79vh" }}
                                    ref={ref}
                                    className="chat-conversation p-3 p-lg-4"
                                    id="messages"
                                >
                                    <ul className="list-unstyled mb-0">
                                        {chatMessages.length > 0 && chatMessages?.map((chat, key) => {
                                            return chat?.isToday && chat.isToday === true ? (
                                                <li key={"dayTitle" + key}>
                                                    <div className="chat-day-title">
                                                        <span className="title">Today</span>
                                                    </div>
                                                </li>
                                            ) : userData?.isGroup === true ? (
                                                <li
                                                    key={key}
                                                    className={chat.userType === "sender" ? "right" : ""}
                                                >
                                                    <div className="conversation-list">
                                                        <div className="chat-avatar" style={{ cursor: 'pointer' }} onClick={() => {
                                                            openProfile(chat)
                                                        }}>
                                                            {chat.userType === "sender" ? (
                                                                <img src={profileImg || imageProfile} alt="Steemit" />
                                                            ) : !chat?.profilePicture ? (
                                                                <div className="chat-user-img align-self-center me-3">
                                                                    <div className="avatar-xs">
                                                                        <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                            {chat.userName && chat.userName.charAt(0)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    src={chat.profilePicture || imageProfile}
                                                                    alt="Steemit"
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="user-chat-content">
                                                            <div className="ctext-wrap">
                                                                <div className="ctext-wrap-content px-2 py-1">
                                                                    {chat.isReply && (
                                                                        <>
                                                                            <p className="mb-0" style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                                                                Replied :
                                                                            </p>
                                                                            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 0, alignSelf: 'flex-start', borderLeft: '4px solid red', borderRadius: 2, opacity: '70%', padding: 10 }}>
                                                                                {chat.replydetails.userName} : {chat.replydetails.message}
                                                                            </p>
                                                                        </>
                                                                    )}
                                                                    {chat.imageMessage && (
                                                                        // image list component
                                                                        <ImageList images={chat.imageMessage} />
                                                                    )}
                                                                    {chat.fileMessage && (
                                                                        //file input component
                                                                        <FileList
                                                                            fileName={chat.fileMessage}
                                                                            fileSize={chat.size}
                                                                        />
                                                                    )}
                                                                    {chat.message && (
                                                                        <span className="mb-0" >
                                                                            {chat.message.split(combinedRegex)
                                                                                .map((part, index) => {
                                                                                    if (part?.match(urlRegex)) {
                                                                                        return (
                                                                                            <a href={part} target="_blank">
                                                                                                <p
                                                                                                    className="mb-0"
                                                                                                    style={{
                                                                                                        color: chat.userType === "sender" ? "" : "#ffff",
                                                                                                        textDecorationLine: "underline",
                                                                                                        maxWidth: "50vw",
                                                                                                        wordWrap: "break-word",
                                                                                                    }}
                                                                                                >
                                                                                                    {part}
                                                                                                </p>
                                                                                            </a>
                                                                                        );
                                                                                    } else if (part?.match(mentionRegex) && chat.isMentioned) {

                                                                                        return (
                                                                                            <span
                                                                                                className="mb-0"
                                                                                                style={{ color: chat.userType === "sender" ? "#FFA500" : "#FFFF00", fontWeight: "bold", cursor: "pointer" }}
                                                                                                onClick={() => { openProfile({ userName: part?.replaceAll("*", "").split("@")[1] }) }}
                                                                                            >
                                                                                                {part?.replaceAll("*", "")}
                                                                                            </span>
                                                                                        );
                                                                                    } else {
                                                                                        return part;
                                                                                    }
                                                                                })}
                                                                        </span>
                                                                    )}
                                                                    {chat.isTyping && (
                                                                        <p className="mb-0">
                                                                            typing
                                                                            <span className="animate-typing">
                                                                                <span className="dot ms-1"></span>
                                                                                <span className="dot ms-1"></span>
                                                                                <span className="dot ms-1"></span>
                                                                            </span>
                                                                        </p>
                                                                    )}
                                                                    {!chat.isTyping && (
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: chat.userType === "sender" ? "flex-end" : "flex-start", gap: 4 }}>
                                                                            <p
                                                                                className="chat-time mb-0"
                                                                                style={{ textAlignLast: "justify" }}
                                                                            >
                                                                                {" "}
                                                                                <span className="align-middle" style={{ width: '20px' }}>
                                                                                    {" "}
                                                                                    {chat.time}{" "}
                                                                                </span>
                                                                            </p>
                                                                            {chat.userType == "sender" ? (
                                                                                chat.status == 1 ? (
                                                                                    <BsCheck2 color="gray" fontSize={15} />
                                                                                ) : (
                                                                                    <BsCheck2All
                                                                                        color={
                                                                                            chat.status == 2 ? "gray" : "#0652c4"
                                                                                        }
                                                                                        fontSize={15}
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {!chat.isTyping && (
                                                                    <UncontrolledDropdown className="align-self-start">
                                                                        <DropdownToggle tag="a">
                                                                            <i className="ri-more-2-fill"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu >
                                                                            <DropdownItem onClick={() => setReply(chat)}>
                                                                                {t("Reply")}{" "}
                                                                                <BsFillReplyFill className="float-end text-muted mt-1" />
                                                                                {/* <i className="bi bi-reply-fill float-end text-muted"></i> */}
                                                                            </DropdownItem>
                                                                            {/* <DropdownItem>{t('Save')} <i className="ri-save-line float-end text-muted"></i></DropdownItem>
                                                                                <DropdownItem onClick={toggle}>Forward <i className="ri-chat-forward-line float-end text-muted"></i></DropdownItem>
                                                                                <DropdownItem onClick={() => deleteMessage(chat.id)}>Delete <i className="ri-delete-bin-line float-end text-muted"></i></DropdownItem> */}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}
                                                            </div>
                                                            {
                                                                <div className="conversation-name" style={{ color: '#7269ef' }}>
                                                                    {chat.userType === "sender"
                                                                        ? userDetails?.name
                                                                        : chat.userName}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            ) : (
                                                <li
                                                    key={key}
                                                    className={chat.userType === "sender" ? "right" : ""}
                                                >
                                                    <div className="conversation-list">
                                                        {
                                                            //logic for display user name and profile only once, if current and last messaged sent by same receiver
                                                            chatMessages[key + 1] ? (
                                                                chatMessages[key].userType ===
                                                                    chatMessages[key + 1].userType ? (
                                                                    <div className="chat-avatar">
                                                                        <div className="blank-div"></div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="chat-avatar">
                                                                        {chat.userType === "sender" ? (
                                                                            <img src={avatar1} alt="Steemit" />
                                                                        ) : userChats.recentChatList[
                                                                            userChats.active_user
                                                                        ].profilePicture === "Null" ? (
                                                                            <div className="chat-user-img align-self-center me-3">
                                                                                <div className="avatar-xs">
                                                                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                                        {userChats.recentChatList[
                                                                                            userChats.active_user
                                                                                        ].name.charAt(0)}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    userChats.recentChatList[
                                                                                        userChats.active_user
                                                                                    ].profilePicture
                                                                                }
                                                                                alt="Steemit"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <div className="chat-avatar">
                                                                    {chat.userType === "sender" ? (
                                                                        <img src={avatar1} alt="Steemit" />
                                                                    ) : userChats.recentChatList[
                                                                        userChats.active_user
                                                                    ].profilePicture === "Null" ? (
                                                                        <div className="chat-user-img align-self-center me-3">
                                                                            <div className="avatar-xs">
                                                                                <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                                                    {userChats.recentChatList[
                                                                                        userChats.active_user
                                                                                    ].name.charAt(0)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                userChats.recentChatList[
                                                                                    userChats.active_user
                                                                                ].profilePicture
                                                                            }
                                                                            alt="Steemit"
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        }

                                                        <div className="user-chat-content">
                                                            <div className="ctext-wrap">
                                                                <div className="ctext-wrap-content">
                                                                    {chat.message && (
                                                                        <p className="mb-0">{chat.message}</p>
                                                                    )}
                                                                    {chat.imageMessage && (
                                                                        // image list component
                                                                        <ImageList images={chat.imageMessage} />
                                                                    )}
                                                                    {chat.fileMessage && (
                                                                        //file input component
                                                                        <FileList
                                                                            fileName={chat.fileMessage}
                                                                            fileSize={chat.size}
                                                                        />
                                                                    )}
                                                                    {chat.isTyping && (
                                                                        <p className="mb-0">
                                                                            typing
                                                                            <span className="animate-typing">
                                                                                <span className="dot ms-1"></span>
                                                                                <span className="dot ms-1"></span>
                                                                                <span className="dot ms-1"></span>
                                                                            </span>
                                                                        </p>
                                                                    )}
                                                                    {!chat.isTyping && (
                                                                        <p className="chat-time mb-0">
                                                                            <i className="ri-time-line align-middle"></i>{" "}
                                                                            <span className="align-middle">
                                                                                {chat.time} <BsCheck2 />
                                                                            </span>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {!chat.isTyping && (
                                                                    <UncontrolledDropdown className="align-self-start">
                                                                        <DropdownToggle tag="a">
                                                                            <i className="ri-more-2-fill"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem>
                                                                                {t("Copy")}{" "}
                                                                                <i className="ri-file-copy-line float-end text-muted"></i>
                                                                            </DropdownItem>
                                                                            <DropdownItem>
                                                                                {t("Save")}{" "}
                                                                                <i className="ri-save-line float-end text-muted"></i>
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={toggle}>
                                                                                Forward{" "}
                                                                                <i className="ri-chat-forward-line float-end text-muted"></i>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => deleteMessage(chat.id)}
                                                                            >
                                                                                Delete{" "}
                                                                                <i className="ri-delete-bin-line float-end text-muted"></i>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                )}
                                                            </div>
                                                            {chatMessages[key + 1] ? (
                                                                chatMessages[key].userType ===
                                                                    chatMessages[key + 1].userType ? null : (
                                                                    <div className="conversation-name">
                                                                        {chat.userType === "sender"
                                                                            ? "Patricia Smith"
                                                                            : userChats.recentChatList[
                                                                                userChats.active_user
                                                                            ].name}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <div className="conversation-name">
                                                                    {chat.userType === "sender"
                                                                        ? "Admin"
                                                                        : userChats.recentChatList[
                                                                            userChats.active_user
                                                                        ].name}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </SimpleBar>

                                <ChatInput onaddMessage={addMessage} chatMessages={chatMessages} reply={reply} removeReply={removeReply} />
                            </>
                        }
                        {/* <Modal backdrop="static" isOpen={modal} centered toggle={toggle}>
                            <ModalHeader toggle={toggle}>Forward to...</ModalHeader>
                            <ModalBody>
                                <CardBody className="p-2">
                                    <SimpleBar style={{ maxHeight: "200px" }}>
                                        <SelectContact handleCheck={() => { }} />
                                    </SimpleBar>
                                    <ModalFooter className="border-0">
                                        <Button color="primary">Forward</Button>
                                    </ModalFooter>
                                </CardBody>
                            </ModalBody>
                        </Modal> */}

                    </div>
                    {/* <UserProfileSidebar activeUser={userChats.recentChatList[userChats.active_user]} /> */}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { active_user } = state.Chat;
    const { userSidebar } = state.Layout;
    return { active_user, userSidebar };
};

export default withRouter(UserChat);
// export default withRouter(connect(mapStateToProps, { openUserSidebar, setFullUser })(UserChat));
