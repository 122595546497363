import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { HiOutlineInformationCircle } from "react-icons/hi2";
// import { connect } from "react-redux";

// import { openUserSidebar, setFullUser } from "../../../redux/actions";

//import images
import user from "../../../assets/Images/users/avatar-4.jpg";
import API from "../../../utils/API";
import CommunityDetailsModal from "./CommunityDetailsModal";
import InAppLoading from "../../../LayOut/InAppLoading";
import { AppContext } from "../../../StateManagement/AppContext";
import ProfileModal from "./ProfileModal";
function UserHead(props) {

  const { userData, setUserData } = useContext(AppContext);
  props = props.recentChatList[0];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [CommunityModal, setCommunityModal] = useState(false);
  const [CommunityDetails, setCommunityDetails] = useState({});
  const [Callmodal, setCallModal] = useState(false);
  const [Videomodal, setVideoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ProfileModalLoading, setProfileModalLoading] = useState(false);
  const [ProfileModalOpen, setProfileModalOpen] = useState(false);
  const [ProfileDetails, setProfileDetails] = useState({});
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggle1 = () => setDropdownOpen1(!dropdownOpen1);
  const toggleCallModal = () => setCallModal(!Callmodal);
  const toggleVideoModal = () => setVideoModal(!Videomodal);

  const openUserSidebar = (e) => {
    e.preventDefault();
    // props.recentChatList.openUserSidebar();
  };
  const ShowCommunitiesDetails = async (e) => {
    try {
      setLoading(true);
      const result = await API().GetCommunityByName(e.accountName);
      setCommunityDetails(result.data.result);
      let accounts = result.data.result.roles.rows
        .filter((element) => {
          return element[3] == "admin" || element[3] == "mod";
        })
        .map((element) => {
          return element[1];
        });
      const results = await API().GetUserdata(accounts);
      if (results.data.status) {
        if (Array.isArray(results.data.data)) {
          let detailsGroup = results.data.data.map((ee) => {
            return {
              ...ee,
              role: result.data.result.roles.rows.filter((element) => {
                return ee.altName == element[1] ? element[3] : false;
              }),
            };
          });
          setCommunityDetails((prevState) => {
            return { ...prevState, accounts: detailsGroup };
          });
        } else {
          let detailsGroup = [
            {
              ...results.data.data,
              role: result.data.result.roles.rows.filter((element) => {
                return results.data.data.name == element[1]
                  ? element[3]
                  : false;
              }),
            },
          ];
          setCommunityDetails((prevState) => {
            return { ...prevState, accounts: detailsGroup };
          });
        }
        setCommunityModal(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const toggleModel = (e) => setCommunityModal(!CommunityModal);

  function closeUserChat(e) {
    e.preventDefault();
    var userChat = document.getElementsByClassName("user-chat");
    if (userChat) {
      userChat[0].classList.remove("user-chat-show");
    }
  }

  function deleteMessage() {
    let allUsers = props.recentChatList.recentChatList;
    let copyallUsers = allUsers;
    copyallUsers[props.recentChatList.active_user].messages = [];

    // console.log(copyallUsers);
    // props.recentChatList.setFullUser(copyallUsers);
  }

  const getuserRoles = async () => {
    try {
      const result = await API().GetUserCommunititesSubscribers(
        props?.user_detail[0]?.name
      );
      let Details = userData.groups;
      Details.filter(
        (a) => a.name == props?.user_detail[0]?.name
      )[0].userRoles = result?.data?.result?.map((element) => {
        return {
          id: element,
          display: element,
        };
      });
      setUserData((prevState) => ({ ...prevState, groups: Details }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props?.isGroup && props?.user_detail[0]?.name) getuserRoles();
  }, [props]);

  const openProfile = async (chat) => {
    try {
      setProfileModalLoading(true);
      const results = await API().GetUserdata(chat);
      setProfileDetails({
        ...results.data.data,
        profile_image: results.data.data.posting_json_metadata
          ? JSON.parse(results.data.data.posting_json_metadata).profile
              .profile_image
          : "",
      });
      setProfileModalLoading(false);
      setProfileModalOpen(true);
    } catch (err) {
      console.log(err);
      setProfileModalLoading(false);
    }
  };

  const toggleProfileModel = () => {
    setProfileModalOpen(!ProfileModalOpen);
  };

  return (
    <React.Fragment>
      <div className="p-3 p-lg-3 border-bottom">
        <InAppLoading isopened={loading || ProfileModalLoading} />
        <ProfileModal
          isopened={ProfileModalOpen}
          toggleModel={toggleProfileModel}
          details={ProfileDetails}
          centered
          scrollable
        />
        <CommunityDetailsModal
          isopened={CommunityModal}
          toggleModel={toggleModel}
          details={CommunityDetails}
          centered
          scrollable
        />
        <Row className="align-items-center">
          <Col>
            <div className="d-flex align-items-center">
              <div className="d-block d-lg-none me-2 ms-0">
                <Link
                  to="#"
                  onClick={(e) => closeUserChat(e)}
                  className="user-chat-remove text-muted font-size-16 p-2"
                >
                  <i className="ri-arrow-left-s-line"></i>
                </Link>
              </div>
              {props && (
                <>
                  {props?.GroupName ? (
                    props.profilePicture !== "Null" ? (
                      <div className="me-3 ms-0">
                      
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                            {props?.GroupName?.charAt(0)}
                          </span>
                        </div>{" "}
                      </div>
                    ) : (
                      <div className="chat-user-img align-self-center me-3">
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                            {props?.GroupName?.charAt(0)}
                          </span>
                        </div>
                      </div>
                    )
                  ) : props?.user_detail[0]?.profile_picture !== "Null" ? (
                    <div className="me-3 ms-0">
                      <img
                        src={props?.user_detail[0]?.profile_picture}
                        className="rounded-circle avatar-xs"
                        alt="Steemit"
                      />
                    </div>
                  ) : (
                    <div className="chat-user-img align-self-center me-3">
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                          {props?.user_detail[0]?.name?.charAt(0)}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-0 text-truncate">
                      {props?.GroupName ? props?.GroupName : props?.GroupName}
                      <Link
                        to="#"
                        onClick={() => {
                          if (props?.GroupName) {
                            ShowCommunitiesDetails(props);
                          } else {
                            openProfile(props?.user_detail[0]?.Name);
                          }
                        }}
                        className="text-reset user-profile-show"
                        style={{ marginLeft: 10 }}
                      >
                        <HiOutlineInformationCircle size={25} />
                      </Link>
                    </h5>
                  </div>
                </>
              )}
              {/* {props?.isGroup == true && <h5 className="font-weight-bold mb-0 text-truncate" style={{ cursor: 'pointer', fontSize: 25 }}>@</h5>} */}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { users, active_user } = state.Chat;
  return { ...state.Layout, users, active_user };
};

export default UserHead;
// export default connect(mapStateToProps, { openUserSidebar, setFullUser })(UserHead);
