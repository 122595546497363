import "./Components/assets/scss/themes.scss";
// import './bootstrap/css/bootstrap.min.css';
import "./Components/Styles/index.css";
import "./Components/assets/scss/themes.scss";
import React, { useEffect, useState, useMemo } from "react";
import SteemProvider from "react-steemlogin";
import { AppContext } from "./Components/StateManagement/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/index";
import { userChat } from "./Components/GlobalVariables";
//SteemLogin Config JSON
import { io } from "socket.io-client";

const socketConn = io(
  // "ws://localhost:3001",
  "https://nizamuddintai-001-site11.ctempurl.com/",
  {
    reconnectionDelayMax: 2000,
    auth: {
      token: "123"
    }
  });
const STEEM_CONFIG = {
  baseURL: "https://steemlogin.com/",
  app: "steem.dapps",
  //callbackURL: "http://nizamuddintai-001-site30.ctempurl.com/",
   callbackURL: "https://backend.steemchat.org/",
  scope: [
    "login",
    "vote",
    "comment",
    "delete_comment",
    "comment_options",
    "custom_json",
  ],
};
const App = () => {
  const [userData, setUserData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [startServer, setstartServer] = useState(0);
  const [userReply, setuserReply] = useState({ status: false });
  const [socket, setSocket] = useState();
  const value = useMemo(
    () => ({
      userData,
      setUserData,
      userDetails,
      setUserDetails,
      startServer,
      setstartServer,
      socket,
      setSocket,
      userReply,
      setuserReply,
    }),
    [
      userData,
      setUserData,
      userDetails,
      setUserDetails,
      startServer,
      setstartServer,
      socket,
      setSocket,
    ]


    [userData, setUserData, userDetails, setUserDetails, startServer, setstartServer, userReply, setuserReply]


  );
  useEffect(() => {
    setSocket(socketConn)
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        if (localStorage.getItem("userId"))
          socket?.emit("JoinChats", parseInt(localStorage.getItem("userId"))) // x8WIv7-mJelg7on_ALbx
      });
      return () => { socket.off("connect") };
    }
  }, [socket, userData]);
  return (
    <SteemProvider config={STEEM_CONFIG}>
      <AppContext.Provider value={value}>
        <Routes />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </AppContext.Provider>
    </SteemProvider>
  );
};

export default App;
