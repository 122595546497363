// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emboss {
  width: 100%;
  /* height: 200px; */
  padding: 20px;
  background: #edf3f9;
  box-shadow: -7px -7px 16px 0 #ffffff,
    7px 7px 10px -4px rgba(116, 150, 179, 0.27);
  border-radius: 18px;
  margin-bottom: 20px;
}
.LoadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #edf3f9;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Styles/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB;+CAC6C;EAC7C,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".emboss {\n  width: 100%;\n  /* height: 200px; */\n  padding: 20px;\n  background: #edf3f9;\n  box-shadow: -7px -7px 16px 0 #ffffff,\n    7px 7px 10px -4px rgba(116, 150, 179, 0.27);\n  border-radius: 18px;\n  margin-bottom: 20px;\n}\n.LoadingScreen {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n  background: #edf3f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
