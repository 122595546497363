import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
// import { connect, useDispatch, useSelector } from "react-redux";

import { setActiveTab, changeLayoutMode } from "../redux/actions";

//Import Images
import logo from "../../Components/assets/Images/logo.svg";
import avatar1 from "../../Components/assets/Images/users/avatar-1.jpg";

//i18n
import i18n from "../../i18n";
import SteemLogo from "../assets/Images/Steemit-logo.png";
// falgs
import usFlag from "../../Components/assets/Images/flags/us.jpg";
import spain from "../../Components/assets/Images/flags/spain.jpg";
import germany from "../../Components/assets/Images/flags/germany.jpg";
import italy from "../../Components/assets/Images/flags/italy.jpg";
import russia from "../../Components/assets/Images/flags/russia.jpg";
import imageProfile from "../../Components/assets/Images/users/altImage.png";
import { useContext } from "react";
import { AppContext } from "../StateManagement/AppContext";
import API from "../utils/API";

function LeftSidebarMenu(props) {
  props = props.props.user;
  // console.log(props);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData, setUserData, socket } = useContext(AppContext);
  const [layoutMode, setlayoutMode] = useState("light");

  const mode = layoutMode === "dark" ? "light" : "dark";
  const onChangeLayoutMode = (value) => {
    setlayoutMode(value);
    // console.log(value, document.body.getAttribute("data-layout-mode"));
    document.body.setAttribute("data-layout-mode", value);
    if (changeLayoutMode) {
      // console.log(mode);
      changeLayoutMode(value);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [lng, setlng] = useState("English");
  const { userDetails, setUserDetails } = useContext(AppContext);

  const [profileImg, setprofileImg] = useState(
    userDetails.posting_json_metadata
      ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image
      : ""
  );
  useEffect(() => {
    const getUserDetails = async () => {
      const userName = localStorage.getItem("userName");
      const results = await API().GetUserdata(userName);
      setprofileImg(
        results.data.data.posting_json_metadata
          ? JSON.parse(results.data.data.posting_json_metadata).profile
              .profile_image
          : ""
      );
    };

    getUserDetails();
  }, []);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    // console.log(tab, 'tabs 1 1 1'); //ye console q nhi hua
    setUserData((prev) => {
      return { ...prev, user: { ...prev.user, activeTab: tab } };
    });
    // console.log(userData, 'tabs walal console');
    // props.setActiveTab(tab)
  };

  const activeTab = props ? props.activeTab : "chat";
  /* changes language according to clicked language menu item */
  const changeLanguageAction = (lng) => {
    /* set the selected language to i18n */
    i18n.changeLanguage(lng);

    if (lng === "sp") setlng("Spanish");
    else if (lng === "gr") setlng("German");
    else if (lng === "rs") setlng("Russian");
    else if (lng === "it") setlng("Italian");
    else if (lng === "eng") setlng("English");
  };

  const LogOut = () => {
    // localStorage.removeItem("steem_access_token");
    localStorage.clear();
    // localStorage.removeItem("id");
    setUserDetails(null);
    socket?.disconnect();
    // console.log(userDetails);
    navigate("/");
  };

  return (
    <React.Fragment>
      <div className="side-menu flex-lg-column me-lg-1">
        {/* LOGO */}
        <div className="navbar-brand-box">
          {/* <Link to="/" className="logo logo-dark"> */}
          <span className="logo-sm logo logo-dark">
            <img src={SteemLogo} alt="logo" height="50" />
          </span>
          {/* </Link> */}

          {/* <Link to="/" className="logo logo-light"> */}
          <span className="logo-sm logo logo-light">
            <img src={SteemLogo} alt="logo" height="50" />
          </span>
          {/* </Link> */}
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <Nav
            pills
            className="side-menu-nav justify-content-center"
            role="tablist"
          >
            {/* <NavItem id="profile">
                            <NavLink id="pills-user-tab" className={classnames({ active: activeTab === 'profile' })} onClick={() => { toggleTab('profile'); }}>
                                <i className="ri-user-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="profile" placement="top">
                            Profile
                        </UncontrolledTooltip> */}
            <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={classnames({ active: activeTab === "chat" })}
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-message-3-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Chats" placement="top">
              Chats
            </UncontrolledTooltip>
            <NavItem id="Groups">
              <NavLink
                id="pills-groups-tab"
                className={classnames({ active: activeTab === "group" })}
                onClick={() => {
                  toggleTab("group");
                }}
              >
                <i className="ri-group-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="Groups" placement="top">
              Communities
            </UncontrolledTooltip>
            {/* <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'contacts' })} onClick={() => { toggleTab('contacts'); }}>
                                <i className="ri-contacts-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contacts
                        </UncontrolledTooltip> */}
            {/* <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: activeTab === 'settings' })} onClick={() => { toggleTab('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem> */}
            {/* <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip> */}
            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none dropup"
            >
              <DropdownToggle nav>
                <img
                  src={profileImg || imageProfile}
                  alt="Steemit"
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  Profile{" "}
                  <i className="ri-profile-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  Setting{" "}
                  <i className="ri-settings-3-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="/" onClick={() => LogOut()}>
                  Log out{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className="flex-lg-column d-none d-lg-block">
          <Nav className="side-menu-nav justify-content-center">
            {/* <Dropdown nav isOpen={dropdownOpen2} className="btn-group dropup profile-user-dropdown" toggle={toggle2}>
                            <DropdownToggle nav>
                                <i className="ri-global-line"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => changeLanguageAction('eng')} active={lng === "English"}>
                                    <img src={usFlag} alt="user" className="me-1" height="12" /> <span className="align-middle">English</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => changeLanguageAction('sp')} active={lng === "Spanish"}>
                                    <img src={spain} alt="user" className="me-1" height="12" /> <span className="align-middle">Spanish</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => changeLanguageAction('gr')} active={lng === "German"}>
                                    <img src={germany} alt="user" className="me-1" height="12" /> <span className="align-middle">German</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => changeLanguageAction('it')} active={lng === "Italian"}>
                                    <img src={italy} alt="user" className="me-1" height="12" /> <span className="align-middle">Italian</span>
                                </DropdownItem>

                                <DropdownItem onClick={() => changeLanguageAction('rs')} active={lng === "Russian"}>
                                    <img src={russia} alt="user" className="me-1" height="12" /> <span className="align-middle">Russian</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown> */}

            <li className="nav-item">
              <NavLink id="about" href="/about" target="_blank">
                <i className="ri-file-info-line"></i>
              </NavLink>
              <UncontrolledTooltip target="about" placement="right">
                About
              </UncontrolledTooltip>
            </li>

            <li className="nav-item">
              <NavLink id="light-dark" onClick={() => onChangeLayoutMode(mode)}>
                <i className="ri-sun-line theme-mode-icon"></i>
              </NavLink>
              <UncontrolledTooltip target="light-dark" placement="right">
                Dark / Light Mode
              </UncontrolledTooltip>
            </li>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              className="nav-item btn-group dropup profile-user-dropdown"
              toggle={toggle}
            >
              <DropdownToggle className="nav-link" tag="a">
                <img
                  src={profileImg || imageProfile}
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  Profile{" "}
                  <i className="ri-profile-line float-end text-muted"></i>
                </DropdownItem>
                {/* <DropdownItem onClick={() => { toggleTab('settings'); }}>Setting <i className="ri-settings-3-line float-end text-muted"></i></DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem onClick={() => LogOut()} href="/">
                  Log out{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default LeftSidebarMenu;
