import React, { Suspense, useEffect } from 'react';
import { Routes as SwitchRoute, Route, Navigate } from 'react-router-dom';

import SignIn from '../Components/Authentiction/SignIn';
import DashBoard from '../Components/Pages/dashboard';

const AuthProtected = (props) => {
    /*
      Navigate is un-auth access protected routes via url
      */

    if (props.isAuthProtected && !localStorage.getItem("authUser")) {
        return (
            <Navigate to={{ pathname: "/", state: { from: props.location } }} />
        );
    }

    return <>{props.children}</>;
};

/**
 * Main Route component
 */
const Routes = () => {
    return (
        // rendering the router with layout
        <React.Fragment>
            <SwitchRoute>
                <Route path="/" element={<SignIn />} />
                <Route path="/dashboard" element={ <DashBoard/> } />
            </SwitchRoute>
        </React.Fragment>
    );
}

export default Routes;