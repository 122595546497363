import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import imageProfile from '../../../assets/Images/users/altImage.png';
function ProfileModal(props) {
    const toggle = () => props.toggleModel();
    const [votingPower, setVotingPower] = useState(0);
    const [ReputationLevel, setReputationLevel] = useState(0);
    // console.log(props, 'cc')
    useEffect(() => {
        if (props?.details?.reputation) {
            let raw = parseInt(props.details.reputation)
            let neg = raw < 0
            let level = Math.log10(Math.abs(raw))
            level = Math.max(level - 9, 0)
            level = (neg ? -1 : 1) * level
            level = parseFloat((level * 9) + 25).toFixed(2)
            setReputationLevel(level)
        }
        if (props?.details) {
            const votingPower = props.details.voting_power;
            const maxVotingPower = 10000;
            let percentage = (votingPower / maxVotingPower) * 100;
            if (percentage == 0) {
                percentage = 100
            }
            setVotingPower(parseFloat(percentage).toFixed(2))
        }
    }, [props?.details?.reputation, props?.details?.voting_power])
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
            <Modal isOpen={props.isopened} toggle={toggle} {...props}>
                <ModalHeader toggle={toggle}>
                    <div className="chat-user-img align-self-center me-3 d-flex flex-row " >
                        <div className="mb-0" style={{
                            position: 'relative',
                            display: 'inline-block',
                        }}>
                            <img
                                src={props?.details?.profile_image || imageProfile}
                                className="rounded-circle avatar-lg img-thumbnail align-self-center"
                                style={{ height: '50px', width: '50px' }}
                                alt="Steemit"
                            />
                        </div>
                        <div className='align-self-center mx-2' style={{ color: '#7269ef' }}>{props?.details?.altName || props?.details?.name}</div>
                        <div className='align-self-center mx-2' style={{
                            background: 'blue',
                            textAlign: 'center',
                            color: 'white',
                            fontSize: '15px',
                            paddingInline: '10px',
                            borderRadius: '30px 30px 30px 30px',
                        }}>Reputaion : {ReputationLevel}</div>
                    </div>
                </ModalHeader>
                <ModalBody>

                    <div className="mt-1" style={{ marginTop: -30 }}>
                        <p className="text-muted mb-1">Voting Power </p>
                        <h5 className="font-size-14"> {votingPower}%</h5>
                    </div>
                    <div >
                        <p className="text-muted mb-1">Balance</p>
                        <h5 className="font-size-14">{props?.details?.balance}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">Savings Balance</p>
                        <h5 className="font-size-14">{props?.details?.savings_balance}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">SBD Balance</p>
                        <h5 className="font-size-14">{props?.details?.sbd_balance}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">Unclaimed Steem Power</p>
                        <h5 className="font-size-14">{props?.details?.reward_vesting_steem}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">Unclaimed Steem Balance</p>
                        <h5 className="font-size-14">{props?.details?.reward_steem_balance}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">Witnesses Voted For</p>
                        <h5 className="font-size-14 mb-0">{props?.details?.witnesses_voted_for}</h5>
                    </div>

                    <div className="mt-1">
                        <p className="text-muted mb-1">Post Count</p>
                        <h5 className="font-size-14 mb-0">{props?.details?.post_count}</h5>
                    </div>
                    <div className="mt-1">
                        <p className="text-muted mb-1">Last Post</p>
                        <h5 className="font-size-14 mb-0">
                            {moment(props?.details?.last_post).format("lll")}</h5>
                    </div>

                    <div className="mt-1">
                        <p className="text-muted mb-1">Account Created</p>
                        <h5 className="font-size-14">
                            {moment(props?.details?.created).format("lll")}
                        </h5>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    );
}

export default ProfileModal;