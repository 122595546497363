import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import imageProfile from "../../../assets/Images/users/altImage.png";
import API from "../../../utils/API";
import CustomCollapse from "../../../chat-components/CustomCollapse";
function ProfileModal(props) {
  const toggle = () => props.toggleModel();
  const [votingPower, setVotingPower] = useState(0);
  const [otherInformation, setOtherInformation] = useState({
    voting_csi: 0,
    selfvote_rate: 0,
    delegators: [],
    delegatorsOutgoing: [],
  });
  const [ReputationLevel, setReputationLevel] = useState(0);
  const [BotFree, setBotFree] = useState(false);
  const [isOpenDelegators, setIsOpenDelegators] = useState(false);
  const [isOpenDelegatee, setIsOpenDelegatee] = useState(false);
  const bots = [
    { botName: "upvu" },
    { botName: "tipu" },
    { botName: "upmewhale" },
    { botName: "robiniaswap" },
    { botName: "justyy" },
    { botName: "sct.krwp" },
    { botName: "coin-doubler" },
    { botName: "nutbox.mine" },
    { botName: "steem-punks" },
    { botName: "boomerang" },
    { botName: "bot-api" },
    { botName: "successgr.with" },
    { botName: "templar-kr" },
    { botName: "gotogether" },
    { botName: "heroism" },
    { botName: "support-kr" },
    { botName: "xiguang" },
    { botName: "steemvote" },
    { botName: "upex" },
    { botName: "abb-curation" },
    { botName: "h4lab" },
  ];

  const getmoreDets = async (userName) => {
    try {
      const ProfileResults = await API().GetProfileDetails(userName);
      const FetchDelegatorsIncoming = await API().GetProfileDelegatorsIncoming(
        userName
      );
      const FetchDelegatorsOutgoing = await API().GetProfileDelegatorsOutGoing(
        userName
      );
      const delegatorsIncoming = FetchDelegatorsIncoming.data.result.rows.map(
        (element) => {
          if (
            bots.filter((ele) => {
              return ele.botName == element[1];
            }).length > 0
          ) {
            setBotFree(true);
          }
          return {
            Name: element[1],
            time: element[0],
            vests: (element[3] / 1738.897).toFixed(2),
          };
        }
      );
      const delegatorsOutgoing = FetchDelegatorsOutgoing.data.result.rows.map(
        (element) => {
          if (
            bots.filter((ele) => {
              return ele.botName == element[2];
            }).length > 0
          ) {
            setBotFree(true);
          }
          return {
            Name: element[2],
            time: element[0],
            vests: (element[3] / 1738.897).toFixed(2),
          };
        }
      );
      setOtherInformation({
        voting_csi: ProfileResults.data.result.voting_csi,
        selfvote_rate: ProfileResults.data.result.selfvote_rate,
        delegators: delegatorsIncoming,
        delegatorsOutgoing: delegatorsOutgoing,
      });
    } catch (err) {

    }
  };

  useEffect(() => {
    if (props?.details?.reputation) {
      let raw = parseInt(props.details.reputation);
      let neg = raw < 0;
      let level = Math.log10(Math.abs(raw));
      level = Math.max(level - 9, 0);
      level = (neg ? -1 : 1) * level;
      level = parseFloat(level * 9 + 25).toFixed(2);
      setReputationLevel(level);
    }
    if (props?.details) {
      const votingPower = props.details.voting_power;
      const maxVotingPower = 10000;
      let percentage = (votingPower / maxVotingPower) * 100;
      if (percentage == 0) {
        percentage = 100;
      }
      setVotingPower(parseFloat(percentage).toFixed(2));
    }
    getmoreDets(props?.details?.name);
    return () =>
      setOtherInformation({
        voting_csi: 0,
        selfvote_rate: 0,
        delegators: [],
        delegatorsOutgoing: [],
      });
  }, [props?.details?.reputation, props?.details?.voting_power]);
  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>
                Click Me
            </Button> */}
      <Modal isOpen={props.isopened} toggle={toggle} {...props}>
        <ModalHeader toggle={toggle}>
          <div className="chat-user-img align-self-center gap-2 gap-md-3  d-flex flex-column flex-md-row ">
            {/* profile icon and name  */}
            <div
            className="d-flex flex-row align-items-center gap-3"
            >
              <img
                src={props?.details?.profile_image || imageProfile}
                className="rounded-circle avatar-lg img-thumbnail align-self-center"
                style={{ height: "50px", width: "50px" }}
                alt="Steemit"
              />
            {props?.details?.altName || props?.details?.name}
            </div>

            {/* reputation  */}
            <div
              className="align-self-center text-white p-1 px-2 rounded-5 "
              style={{
                background: "blue",
                fontSize: "15px",
              }}
            >
              Reputaion : {ReputationLevel}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="mt-1" style={{ marginTop: -30 }}>
            <p className="text-muted mb-1">Voting Power </p>
            <h5 className="font-size-14"> {votingPower}%</h5>
          </div>
          <div>
            <p className="text-muted mb-1">Balance</p>
            <h5 className="font-size-14">{props?.details?.balance}</h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Savings Balance</p>
            <h5 className="font-size-14">{props?.details?.savings_balance}</h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">SBD Balance</p>
            <h5 className="font-size-14">{props?.details?.sbd_balance}</h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Unclaimed Steem Power</p>
            <h5 className="font-size-14">
              {props?.details?.reward_vesting_steem}
            </h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Unclaimed Steem Balance</p>
            <h5 className="font-size-14">
              {props?.details?.reward_steem_balance}
            </h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Witnesses Voted For</p>
            <h5 className="font-size-14 mb-0">
              {props?.details?.witnesses_voted_for}
            </h5>
          </div>

          <div className="mt-1">
            <p className="text-muted mb-1">Post Count</p>
            <h5 className="font-size-14 mb-0">{props?.details?.post_count}</h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Last Post</p>
            <h5 className="font-size-14 mb-0">
              {moment(props?.details?.last_post).format("lll")}
            </h5>
          </div>

          <div className="mt-1">
            <p className="text-muted mb-1">Account Created</p>
            <h5 className="font-size-14">
              {moment(props?.details?.created).format("lll")}
            </h5>
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Bot Free Account</p>
            {BotFree ? (
              <h5
                className="font-size-14 mb-0"
                style={{
                  backgroundColor: "red",
                  padding: 5,
                  color: "white",
                  borderRadius: 5,
                  width: "30px",
                }}
              >
                No
              </h5>
            ) : (
              <h5
                className="font-size-14 mb-0"
                style={{
                  backgroundColor: "green",
                  padding: 5,
                  color: "white",
                  borderRadius: 5,
                  width: "35px",
                }}
              >
                Yes
              </h5>
            )}
          </div>
          <div className="mt-1">
            <p className="text-muted mb-1">Voting CSI</p>
            <h5 className="font-size-14">{otherInformation.voting_csi}</h5>
          </div>
          <div className="mt-1 mb-2">
            <p className="text-muted mb-1">Self Vote Ratio</p>
            <h5 className="font-size-14">{otherInformation.selfvote_rate}</h5>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            <div style={{ width: "80%" }}>
              <CustomCollapse
                title="Delegators"
                isOpen={isOpenDelegators}
                toggleCollapse={() => setIsOpenDelegators(!isOpenDelegators)}
              >
                <div style={{ padding: 10 }}>
                  {/* <p className="text-muted mb-1">Delegators</p> */}
                  {otherInformation?.delegators?.length == 0 ||
                  !otherInformation ||
                  !otherInformation?.delegators ? (
                    <h5 className="font-size-14">No Deligators to Show !</h5>
                  ) : (
                    otherInformation?.delegators.map((element, i) => {
                      return (
                        <React.Fragment key={i}>
                          {bots.filter((ele) => {
                            return ele.botName == element.Name;
                          }).length > 0 ? (
                            <h5
                              className="font-size-14 p-1"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: 5,
                              }}
                            >
                              {/* {i + 1}) Name : */}
                              {element.Name} <br /> Delegated SP :{" "}
                              {element.vests}
                              {/* Time:{moment(new Date(element.time)).format("lll")} */}
                            </h5>
                          ) : (
                            <h5 className="font-size-14 p-1">
                              {/* {i + 1}) Name : */}
                              {element.Name} <br /> Delegated SP :{" "}
                              {element.vests}
                              {/* Time:{moment(new Date(element.time)).format("lll")} */}
                            </h5>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </div>
              </CustomCollapse>
            </div>
            <div style={{ width: "80%" }}>
              <CustomCollapse
                title="Delegatees"
                isOpen={isOpenDelegatee}
                toggleCollapse={() => setIsOpenDelegatee(!isOpenDelegatee)}
              >
                <div style={{ padding: 10 }}>
                  {otherInformation?.delegatorsOutgoing?.length == 0 ||
                  !otherInformation ||
                  !otherInformation?.delegatorsOutgoing ? (
                    <h5 className="font-size-14">No Deligators to Show !</h5>
                  ) : (
                    otherInformation?.delegatorsOutgoing.map((element, i) => {
                      return (
                        <React.Fragment key={i}>
                          {bots.filter((ele) => {
                            return ele.botName == element.Name;
                          }).length > 0 ? (
                            <h5
                              className="font-size-14 p-1"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                borderRadius: 5,
                              }}
                            >
                              {/* {i + 1}) Name :  */}
                              {element.Name} <br /> Delegated SP :{" "}
                              {element.vests}
                              {/* Time:{moment(new Date(element.time)).format("lll")} */}
                            </h5>
                          ) : (
                            <h5 className="font-size-14 p-1">
                              {/* {i + 1}) Name :  */}
                              {element.Name} <br /> Delegated SP :{" "}
                              {element.vests}
                              {/* Time:{moment(new Date(element.time)).format("lll")} */}
                            </h5>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </div>
              </CustomCollapse>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ProfileModal;
