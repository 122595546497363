import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Form,
  Label,
  Input,
  Collapse,
  CardHeader,
  CardBody,
  Alert,
  InputGroup,
  Card,
  Badge,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
// import { connect } from 'react-redux';

import { withTranslation } from "react-i18next";

// simple bar
import SimpleBar from "simplebar-react";

// components
import SelectContact from "../../../chat-components/SelectContact";
import { AppContext } from "../../../StateManagement/AppContext";
import { useContext } from "react";
import API from "../../../utils/API";

function Groups({ props, t }) {
  // console.log(props);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const { userDetails, userData, setUserData, socket } = useContext(AppContext);

  const elementRef = useRef(null);
  const toggle = () => {
    setModal(!modal);
  };

  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse);
  };

  const getCommunities = async () => {
    try {
      const userName = localStorage.getItem("userName") || "";
      console.log("🚀 ~ getCommunities ~ userName:", userName)
      const res1 = await API().GetUserCommunitites(
        // userDetails.name || userName 
        'steem.dapps'
      );
      // const res1 = await API().GetUserCommunitites(
      //   userDetails.name || userName
      // );
      
      let data = res1.data.result.rows.map((element) => {


        return {
          title: element[11],
          id: element[0],
          account: element[2],
          description: element[14],
        };
      });
      updateGroups(data || " ");
    } catch (err) {
      console.log(err, "testtinggggg");
    }
  };

  useEffect(()=>{
    getCommunities()
  },[])
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (loading) getCommunities();
        // Example of fetching data when element is in focus
      }
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const updateGroups = async (tempGroup) => {

    try {
      setLoading(true)
      const res3 = await API().UpdateCommunities(
        tempGroup,
        parseInt(localStorage.getItem("id") || localStorage.getItem("userId"))
      );
      console.log("🚀 ~ updateGroups ~ res3:", res3)
      if (res3.data.status) {
        const res4 = await API().GetCommunititesData(
          parseInt(localStorage.getItem("id") || localStorage.getItem("userId"))
        );
        console.log("🚀 ~ updateGroups ~ res4:", res4)
        setUserData((prevstate) => {
          return {
            ...prevstate,
            groups: res4.data.result.map((e) => {
              return {
                ...e,
                messages: [],
                members: [],
              };
            }),
          };
        });
        socket?.emit(
          "JoinChats",
          parseInt(localStorage.getItem("id") || localStorage.getItem("userId"))
        );

        setLoading(false);
      }
      // console.log(res3.data, "cccccommmms");
    } catch (err) {
      console.log(err, "errrr");
      setLoading(false);

    }
  };
  // useEffect(() => {
  //   // console.log(userData);
  //   updateGroups();
  //   // setGroups(props);
  // }, [tempGroup]);

  // const handleCheck = (e, contactId) => {
  //   Handle the logic for checking/unchecking a contact here
  // };

  const handleChangeGroupName = (e) => {
    // console.log(e,{...userData,active_group:e.gourpId});
    // const obj12={...userData,active_group:e["gourpId"],isGroup:true,active_user:null};
    // console.log(obj12,userData);

    setUserData((prevstate) => ({
      ...prevstate,
      active_group: e["groupId"],
      isGroup: true,
      active_user: null,
    }));

    var chatList = document.getElementById("chat-list");
    var clickedItem = e.target;
    var currentli = null;

    if (chatList) {
      var li = chatList.getElementsByTagName("li");
      //remove coversation user
      for (var i = 0; i < li.length; ++i) {
        if (li[i].classList.contains("active")) {
          li[i].classList.remove("active");
        }
      }
      //find clicked coversation user
      for (var k = 0; k < li.length; ++k) {
        if (li[k].contains(clickedItem)) {
          currentli = li[k];
          break;
        }
      }
    }

    //activation of clicked coversation user
    if (currentli) {
      currentli.classList.add("active");
    }

    var userChat = document.getElementsByClassName("user-chat");
    if (userChat) {
      userChat[0].classList.add("user-chat-show");
    }

    //removes unread badge if user clicks
    // var unread = document.getElementById("unRead" + chat.id);
    // if (unread) {
    //   unread.style.display = "none";
    // }

    // console.log(e["groupId"], 'test 1', userData, "group js");

    // document.querySelectorAll(".group-list .active").forEach((element) => {
    //   element.classList.remove("active");
    // });

    // var li = document.getElementById("group" + e["groupId"]);
    // if (li) {
    //   li.classList.add("active");
    // }
  };

  // useEffect(()=>{console.log(userData,'useEffect Console')},[userData])

  // const handleChangeGroupDesc = (e) => {
  //   setGroupDesc(e.target.value);
  // };

  return (
    <React.Fragment>
      <div ref={elementRef}>
        <div className="p-4">
          <div className="user-chat-nav float-end">
            <div id="create-group">
              {/* Button trigger modal */}
              {/* <Button
                onClick={toggle}
                type="button"
                color="link"
                className="text-decoration-none text-muted font-size-18 py-0"
              > */}
              <i className="ri-group-line me-1"></i>
              {/* </Button> */}
            </div>
            {/* <UncontrolledTooltip target="create-group" placement="bottom">
              Create group
            </UncontrolledTooltip> */}
          </div>
          <h4 className="mb-0">{t("Communities")}</h4>
        </div>

        {/* Start chat-group-list */}
        <SimpleBar
          style={{ maxHeight: "100%" }}
          className="p-4 chat-message-list chat-group-list"
        >
          <ul className="list-unstyled chat-list group-list">
            {loading ? (
              <div className="d-flex w-100 justify-content-center align-items-center flex-column gap-2">
                <Spinner>Loading...</Spinner>
                Fetching Communities ...
              </div>
            ) : userData?.groups?.length == 0  ? (
              <div style={{ textAlign: "center", alignSelf: "center" }}>
                No Communitites to Show
              </div>
            ) : (
              userData?.groups?.map((group, key) => (
                <li key={key} id={"group" + (key + 1)}>
                  <Link to="#" onClick={() => handleChangeGroupName(group)}>
                    <div className="d-flex align-items-center">
                      <div className="chat-user-img me-3 ms-0">
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                            {group.name.charAt(0)}
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="text-truncate font-size-14 mb-0">
                          {group.name}
                          {/* {group.unRead !== 0 ? (
                          <Badge
                            color="none"
                            pill
                            className="badge-soft-danger float-end"
                          >
                            {group.unRead >= 20
                              ? group.unRead + "+"
                              : group.unRead}
                          </Badge>
                        ) : null}
                        {group.isNew && (
                          <Badge
                            color="none"
                            pill
                            className="badge-soft-danger float-end"
                          >
                            {t("New")}
                          </Badge>
                        )} */}
                        </h5>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            )}
          </ul>
        </SimpleBar>
        {/* End chat-group-list */}
      </div>
    </React.Fragment>
  );
}

// const mapStateToProps = (state) => {
//   const { groups, active_user } = state.Chat;
//   return { groups, active_user };
// };

export default withTranslation()(Groups);
// export default connect(mapStateToProps, { createGroup })(withTranslation()(Groups));
