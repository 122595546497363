import React, { useState, useContext, useEffect } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Card,
} from "reactstrap";

//Import components
// import CustomCollapse from "../../../components/CustomCollapse";
// import AttachedFiles from "../../../components/AttachedFiles";
import imageProfile from '../../../assets/Images/users/altImage.png';
import API from "../../../utils/API";
import moment from "moment";
//i18n
import { useTranslation } from "react-i18next";
import CustomCollapse from "../../../chat-components/CustomCollapse";
import AttachedFiles from "../../../chat-components/AttachedFiles";
import { AppContext } from "../../../StateManagement/AppContext";

function Profile(props) {
  const { userDetails, setUserDetails } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileImg, setprofileImg] = useState(userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image : '');
  const [votingPower, setVotingPower] = useState(0);
  const [ReputationLevel, setReputationLevel] = useState(0);
  const [isOpen1, setIsOpen1] = useState(true);
  const [BotFree, setBotFree] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const bots = [
    { botName: "upvu" },
    { botName: "tipu" },
    { botName: "upmewhale" },
    { botName: "robiniaswap" },
    { botName: "justyy" },
    { botName: "sct.krwp" },
    { botName: "coin-doubler" },
    { botName: "nutbox.mine" },
    { botName: "steem-punks" },
    { botName: "boomerang" },
    { botName: "bot-api" },
    { botName: "successgr.with" },
    { botName: "templar-kr" },
    { botName: "gotogether" },
    { botName: "heroism" },
    { botName: "support-kr" },
    { botName: "xiguang" },
    { botName: "steemvote" },
    { botName: "upex" },
    { botName: "abb-curation" },
    { botName: "h4lab" },

  ]
  const savedUserName = localStorage?.getItem("userName");

  const { t } = useTranslation();

  const toggleCollapse1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  const toggleCollapse3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleCollapse4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleCollapse2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(false);
  };

  const getmoreDets = async () => {
    try {
      const ProfileResults = await API().GetProfileDetails(savedUserName);
      const FetchDelegatorsIncoming = await API().GetProfileDelegatorsIncoming(savedUserName);
      const FetchDelegatorsOutgoing = await API().GetProfileDelegatorsOutGoing(savedUserName);
      const delegatorsIncoming = FetchDelegatorsIncoming.data.result.rows.map((element) => {
        if (bots.filter((ele) => { return ele.botName == element[1] }).length > 0) { setBotFree(true) }
        return { Name: element[1], time: element[0], vests: (element[3] / 1738.897).toFixed(2) }
      })
      const delegatorsOutgoing = FetchDelegatorsOutgoing.data.result.rows.map((element) => {
        if (bots.filter((ele) => { return ele.botName == element[2] }).length > 0) { setBotFree(true) }
        return { Name: element[2], time: element[0], vests: (element[3] / 1738.897).toFixed(2) }
      })
      setUserDetails((prevState) => ({
        ...prevState,
        voting_csi: ProfileResults.data.result.voting_csi,
        selfvote_rate: ProfileResults.data.result.selfvote_rate,
        delegators: delegatorsIncoming,
        delegatorsOutgoing: delegatorsOutgoing
      }))
    } catch (err) {
      console.log(err, 'getmoreDets')
    }
  }

  useEffect(() => {
    getmoreDets()
  }, [])

  useEffect(() => {
    if (userDetails.reputation) {
      let raw = parseInt(userDetails.reputation)
      let neg = raw < 0
      let level = Math.log10(Math.abs(raw))
      level = Math.max(level - 9, 0)
      level = (neg ? -1 : 1) * level
      level = parseFloat((level * 9) + 25).toFixed(2)
      setReputationLevel(level)
    }
    if (userDetails) {
      const votingPower = userDetails.voting_power;
      const maxVotingPower = 10000;
      let percentage = (votingPower / maxVotingPower) * 100;
      if (percentage == 0) {
        percentage = 100
      }
      setVotingPower(percentage)
    }
  }, [userDetails.reputation, userDetails.voting_power])

  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <React.Fragment>
      <div>
        <div className="px-4 pt-3">
          <h4 className="mb-0">{t("My Profile")}</h4>
        </div>

        <div className="text-center py-1 border-bottom">
          <div className="mb-0" style={{
            position: 'relative',
            display: 'inline-block',
          }}>
            <span style={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              background: 'blue',
              textAlign: 'center',
              borderRadius: '30px 30px 30px 30px',
              color: 'white',
              padding: '5px 5px',
              fontSize: '10px',
              fontWeight: 'bold'
            }}>{ReputationLevel}</span>
            <img
              src={profileImg || imageProfile}
              className="rounded-circle avatar-lg img-thumbnail align-self-center"
              alt="Steemit"
            />
          </div>

          <h5 className="font-size-16 text-truncate" style={{ color: '#7269ef' }}>
            {savedUserName} <br />Voting Power : {votingPower}%
          </h5>
          {/* <p className="text-muted text-truncate mb-1">
            <i className="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>{" "}
            {t("Active")}
          </p> */}
        </div>
        {/* End profile user  */}

        {/* Start user-profile-desc */}
        <div className="p-4 user-profile-desc" style={{ marginTop: -2, alignSelf: 'center', height: '70vh', overflow: 'auto' }}>
          <div className="text-muted">
            <p className="mb-3" style={{ marginTop: -17, textAlign: 'center' }}>Steemit User Profile</p>
          </div>

          <div id="profile-user-accordion-1" className="custom-accordion">
            <Card className="shadow-none border " style={{ marginTop: -10 }}>
              {/* import collaps */}
              <CustomCollapse
                title="About"
                iconClass="ri-user-2-line"
                isOpen={isOpen1}
                toggleCollapse={toggleCollapse1}
              >
                {/* <div style={{ marginTop: -20 }}>
                  <p className="text-muted mb-1">{t("Name")}</p>
                  <h5 className="font-size-14">{savedUserName}</h5>
                </div> */}

                <div style={{ marginTop: -20 }}>
                  <p className="text-muted mb-1">Balance</p>
                  <h5 className="font-size-14">{userDetails.balance}</h5>
                </div>
                <div className="mt-1">
                  <p className="text-muted mb-1">Savings Balance</p>
                  <h5 className="font-size-14">{userDetails.savings_balance}</h5>
                </div>
                <div className="mt-1">
                  <p className="text-muted mb-1">SBD Balance</p>
                  <h5 className="font-size-14">{userDetails.sbd_balance}</h5>
                </div>

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">Voting Power</p>
                  <h5 className="font-size-14">{userDetails.voting_power}</h5>
                </div> */}

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">Reward Vesting Balance</p>
                  <h5 className="font-size-14">{userDetails.reward_vesting_balance}</h5>
                </div> */}
                <div className="mt-1">
                  <p className="text-muted mb-1">Unclaimed Steem Power</p>
                  <h5 className="font-size-14">{userDetails.reward_vesting_steem}</h5>
                </div>
                <div className="mt-1">
                  <p className="text-muted mb-1">Unclaimed Steem Balance</p>
                  <h5 className="font-size-14">{userDetails.reward_steem_balance}</h5>
                </div>

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">{t("Reputation")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.reputation}</h5>
                </div> */}

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">{t("Curation Rewards")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.curation_rewards}</h5>
                </div> */}
                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Bot Free Account")}</p>
                  {BotFree ?
                    <h5 className="font-size-14 mb-0" style={{ backgroundColor: 'red', padding: 5, color: 'white', borderRadius: 5, width: '30px' }}>No</h5>
                    : <h5 className="font-size-14 mb-0" style={{ backgroundColor: 'green', padding: 5, color: 'white', borderRadius: 5, width: '35px' }}>Yes</h5>
                  }
                </div>

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">{t("Delegated Vesting Shares")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.delegated_vesting_shares}</h5>
                </div> */}
                {/* 
                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Recieved Vesting Shares")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.received_vesting_shares}</h5>
                </div> */}

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">{t("Lifetime Vote Count")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.lifetime_vote_count}</h5>
                </div> */}

                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Witnesses Voted For")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.witnesses_voted_for}</h5>
                </div>

                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Post Count")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.post_count}</h5>
                </div>

                {/* <div className="mt-1">
                  <p className="text-muted mb-1">{t("Posting Rewards")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails.posting_rewards}</h5>
                </div> */}
                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Voting CSI")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails?.voting_csi ? userDetails.voting_csi : '0'}</h5>
                </div>
                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Self Vote Ratio")}</p>
                  <h5 className="font-size-14 mb-0">{userDetails?.selfvote_rate ? userDetails.selfvote_rate : '0'}</h5>
                </div>

                <div className="mt-1">
                  <p className="text-muted mb-1">{t("Last Post")}</p>
                  <h5 className="font-size-14 mb-0">
                    {moment(userDetails.last_post).format("lll")}</h5>
                </div>

                <div className="mt-1">
                  <p className="text-muted mb-1">Account Created</p>
                  <h5 className="font-size-14">
                    {moment(userDetails.created).format("lll")}
                  </h5>
                </div>

                <CustomCollapse
                  title="Delegators"
                  isOpen={isOpen3}
                  toggleCollapse={toggleCollapse3}
                >
                  <div style={{ marginTop: -20 }}>
                    {/* <p className="text-muted mb-1">Delegators</p> */}
                    {userDetails?.delegators?.length == 0 || !userDetails || !userDetails?.delegators ?
                      <h5 className="font-size-14">
                        No Deligators to Show !
                      </h5>
                      : userDetails?.delegators.map((element, i) => {
                        return (

                          <React.Fragment key={i}>
                            {bots.filter((ele) => { return ele.botName == element.Name }).length > 0 ?
                              <h5 className="font-size-14 p-1" style={{ backgroundColor: 'red', color: 'white', borderRadius: 5 }}>
                                {/* {i + 1}) Name : */}
                                {element.Name} <br /> Delegated SP : {element.vests}
                                {/* Time:{moment(new Date(element.time)).format("lll")} */}
                              </h5> :
                              <h5 className="font-size-14 p-1">
                                {/* {i + 1}) Name : */}
                                {element.Name} <br /> Delegated SP : {element.vests}
                                {/* Time:{moment(new Date(element.time)).format("lll")} */}
                              </h5>
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </div>

                </CustomCollapse>
                <CustomCollapse
                  title="Delegatees"
                  isOpen={isOpen4}
                  toggleCollapse={toggleCollapse4}
                >
                  <div style={{ marginTop: -20 }}>
                    {userDetails?.delegatorsOutgoing?.length == 0 || !userDetails || !userDetails?.delegatorsOutgoing ?
                      <h5 className="font-size-14">
                        No Deligators to Show !
                      </h5>
                      : userDetails?.delegatorsOutgoing.map((element, i) => {
                        return (
                          <React.Fragment key={i}>
                            {bots.filter((ele) => { return ele.botName == element.Name }).length > 0 ?
                              <h5 className="font-size-14 p-1" style={{ backgroundColor: 'red', color: 'white', borderRadius: 5 }}>
                                {/* {i + 1}) Name :  */}
                                {element.Name} <br /> Delegated SP : {element.vests}
                                {/* Time:{moment(new Date(element.time)).format("lll")} */}
                              </h5> :
                              <h5 className="font-size-14 p-1">
                                {/* {i + 1}) Name :  */}
                                {element.Name} <br /> Delegated SP : {element.vests}
                                {/* Time:{moment(new Date(element.time)).format("lll")} */}
                              </h5>

                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </CustomCollapse>
              </CustomCollapse>
            </Card>
            {/* End About card  */}

            {/* <Card className="mb-1 shadow-none border"> */}
            {/* import collaps */}
            {/* <CustomCollapse
                title="Attached Files"
                iconClass="ri-attachment-line"
                isOpen={isOpen2}
                toggleCollapse={toggleCollapse2}
              > */}
            {/* attached files */}
            {/* <AttachedFiles files={files} />
              </CustomCollapse> */}
            {/* </Card> */}
            {/* End Attached Files card  */}
          </div>
          {/* end profile-user-accordion  */}
        </div >
        {/* end user-profile-desc  */}
      </div >
    </React.Fragment >
  );
}

export default Profile;