export var userChat = {
    "active_user": 3,
    "userSidebar": false,
    "recentChatList": [
        // {
        //     "id": 0,
        //     "name": "Patrick Hendricks",
        //     "profilePicture": "/static/media/avatar-2.feb0f89de58f0ef9b424.jpg",
        //     "status": "online",
        //     "unRead": 0,
        //     "roomType": "contact",
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 1,
        //             "message": "hi",
        //             "time": "01:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "hi patrick",
        //             "time": "10.00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 3,
        //             "message": "how's going on your project?",
        //             "time": "01:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "message": "Do you need any help?",
        //             "time": "01:06",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 5,
        //             "message": "Let me know?",
        //             "time": "01:06",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 6,
        //             "message": "hi...Good Morning!",
        //             "time": "09:05",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 7,
        //             "message": "image",
        //             "time": "10:30",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //                 },
        //                 {
        //                     "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 8,
        //             "message": "please, save this pictures to your file and give it to me after you have done with editing!",
        //             "time": "10:31",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 9,
        //             "message": "okay sure😄👍",
        //             "time": "02:50",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 1,
        //     "name": "Mark Messer",
        //     "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //     "status": "away",
        //     "unRead": 2,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hello",
        //             "time": "10.00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "images",
        //             "time": "10:30",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //                 },
        //                 {
        //                     "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     "id": 13,
        //     "name": "General",
        //     "profilePicture": "Null",
        //     "unRead": 0,
        //     "isGroup": true,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "userName": "John Smith",
        //             "message": "Hello send project images",
        //             "time": "12:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "userName": "Steve Walker",
        //             "message": "Images",
        //             "time": "12:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 3,
        //             "userName": "steem.dapps",
        //             "message": "Good Afternoon everyone !",
        //             "time": "2:05",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "userName": "Galen Rizo",
        //             "message": "This theme is Awesome!",
        //             "time": "2:06",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 4,
        //     "name": "Doris Brown",
        //     "profilePicture": "/static/media/avatar-4.b23e41d9c09997efbc21.jpg",
        //     "status": "online",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "isTyping": true,
        //     "messages": [
        //         {
        //             "id": 1,
        //             "userName": "Doris Brown",
        //             "message": "Good Morning",
        //             "time": "10:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "userName": "steem.dapps",
        //             "message": "Good morning, How are you? What about our next meeting?",
        //             "time": "10:02",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 3,
        //             "message": "Yeah everything is fine",
        //             "time": "10:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "message": "& Next meeting tomorrow 10.00AM",
        //             "time": "10:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 5,
        //             "message": "Wow that's great",
        //             "time": "10:06",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 6,
        //             "message": "images",
        //             "time": "10:30",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //                 },
        //                 {
        //                     "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 7,
        //             "userName": "steem.dapps",
        //             "message": "Files",
        //             "time": "01:30",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": true,
        //             "fileMessage": "admin_v1.0.zip",
        //             "size": "12.5 MB"
        //         },
        //         {
        //             "id": 8,
        //             "message": "",
        //             "time": "10:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false,
        //             "isTyping": true
        //         }
        //     ]
        // },
        // {
        //     "id": 5,
        //     "name": "Designer",
        //     "profilePicture": "Null",
        //     "unRead": 1,
        //     "isGroup": true,
        //     "messages": [
        //         {
        //             "id": 1,
        //             "userName": "Doris Brown",
        //             "message": "Hello send project images",
        //             "time": "12:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 2,
        //             "userName": "Steve Walker",
        //             "message": "Images",
        //             "time": "12:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 3,
        //             "userName": "steem.dapps",
        //             "message": "Images",
        //             "time": "01:30",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": true,
        //             "fileMessage": "Minible-Vertical.zip"
        //         },
        //         {
        //             "id": 4,
        //             "userName": "steem.dapps",
        //             "message": "@Doris Brown please review this code, and give me feedback asap",
        //             "time": "01:31",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 5,
        //             "userName": "John Howard",
        //             "message": "Good Afternoon everyone !",
        //             "time": "2:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 6,
        //             "userName": "steem.dapps",
        //             "message": "Good Afternoon everyone !",
        //             "time": "2:05",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 9,
        //             "userName": "John Howard",
        //             "message": "Next meeting tomorrow 10.00AM",
        //             "time": "2:10",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 6,
        //     "name": "Steve Walker",
        //     "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //     "status": "away",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "file",
        //             "time": "01:16",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": true,
        //             "fileMessage": "Minible-Vertical.zip"
        //         },
        //         {
        //             "id": 2,
        //             "message": "Okay 👍, let me check it and get back to you soon",
        //             "time": "01:16",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 7,
        //     "name": "Albert Rodarte",
        //     "profilePicture": "Null",
        //     "status": "online",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "isTyping": true,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hi",
        //             "time": "01:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "Hello, how can i help you",
        //             "time": "01:05",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 3,
        //             "message": "",
        //             "time": "01:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false,
        //             "isTyping": true
        //         }
        //     ]
        // },
        // {
        //     "id": 8,
        //     "name": "Mirta George",
        //     "profilePicture": "Null",
        //     "status": "online",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hi...Good Morning!",
        //             "time": "09:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "image",
        //             "time": "10:30",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //                 },
        //                 {
        //                     "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 3,
        //             "message": "please, save this pictures to your file and give it to me after you have done with editing!",
        //             "time": "10:31",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "message": "Yeah, Everything is fine👍",
        //             "time": "02:50",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 9,
        //     "name": "Paul Haynes",
        //     "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //     "status": "away",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hi...Good Morning!",
        //             "time": "09:05",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "image",
        //             "time": "10:30",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //                 },
        //                 {
        //                     "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 3,
        //             "message": "please, save this pictures to your file and give it to me after you have done with editing!",
        //             "time": "10:31",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "message": "Good Morning😄",
        //             "time": "02:50",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 2,
        //     "name": "Jonathan Miller",
        //     "profilePicture": "/static/media/avatar-2.feb0f89de58f0ef9b424.jpg",
        //     "status": "online",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hello Admin",
        //             "time": "08:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "Good morning",
        //             "time": "08:00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 3,
        //             "message": "is everything is fine ?",
        //             "time": "08:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 4,
        //             "message": "i can help you😊",
        //             "time": "08:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 5,
        //             "message": "Hi, How are You?",
        //             "time": "08:00",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // },
        // {
        //     "id": 3,
        //     "name": "Ossie Wilson",
        //     "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //     "status": "away",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hi",
        //             "time": "12:00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "Did you finished it?",
        //             "time": "12:00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "I've finished it! See you so",
        //             "time": "12:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     "id": 14,
        //     "name": "Sara Muller",
        //     "profilePicture": "Null",
        //     "status": "offline",
        //     "unRead": 0,
        //     "isGroup": false,
        //     "messages": [
        //         {
        //             "id": 33,
        //             "isToday": true
        //         },
        //         {
        //             "id": 1,
        //             "message": "hi yana",
        //             "time": "12:00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         },
        //         {
        //             "id": 2,
        //             "message": "image",
        //             "time": "12:05",
        //             "userType": "receiver",
        //             "status": "2",
        //             "isImageMessage": true,
        //             "isFileMessage": false,
        //             "imageMessage": [
        //                 {
        //                     "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 3,
        //             "message": "Wow that's great",
        //             "time": "12:00",
        //             "userType": "sender",
        //             "status": "2",
        //             "isImageMessage": false,
        //             "isFileMessage": false
        //         }
        //     ]
        // }
    ],
    "router": {
        "location": {
            "pathname": "/dashboard",
            "search": "",
            "hash": "",
            "state": null,
            "key": "2n1pa6dw"
        },
        "params": {}
    },
    groups: [
        // {
        //     "gourpId": 1,
        //     "name": "#General",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 0,
        //     "desc": "General Group",
        //     "messages":[{
        //         "id": 1,
        //         "userId":4,
        //         "message": "hi",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 2,
        //         "userId":2,
        //         "message": "hi patrick",
        //         "time": "10.00",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 3,
        //         "userId":3,
        //         "message": "how's going on your project?",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 4,
        //         "userId":2,
        //         "message": "Do you need any help?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 33,
        //         "isToday": true
        //     },
        //     {
        //         "id": 5,
        //         "userId":1,
        //         "message": "Let me know?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 6,
        //         "userId":6,
        //         "message": "hi...Good Morning!",
        //         "time": "09:05",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 7,
        //         "userId":3,
        //         "message": "image",
        //         "time": "10:30",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": true,
        //         "isFileMessage": false,
        //         "imageMessage": [
        //             {
        //                 "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
        //             },
        //             {
        //                 "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
        //             }
        //         ]
        //     },
        //     {
        //         "id": 8,
        //         "userId":4,
        //         "message": "please, save this pictures to your file and give it to me after you have done with editing!",
        //         "time": "10:31",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     }],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // },
        // {
        //     "gourpId": 2,
        //     "name": "#Reporting",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 23,
        //     "desc": "reporing Group here...",
        //     "messages":[{
        //         "id": 1,
        //         "userId":4,
        //         "message": "hi",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 2,
        //         "userId":2,
        //         "message": "hi patrick",
        //         "time": "10.00",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 3,
        //         "userId":3,
        //         "message": "how's going on your project?",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 4,
        //         "userId":2,
        //         "message": "Do you need any help?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     }],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // },
        // {
        //     "gourpId": 3,
        //     "name": "#Designer",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 0,
        //     "isNew": true,
        //     "desc": "designers Group",
        //     "messages":[],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // },
        // {
        //     "gourpId": 4,
        //     "name": "#Developers",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 0,
        //     "desc": "developers Group",
        //     "messages":[{
        //         "id": 1,
        //         "userId":4,
        //         "message": "hi",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 2,
        //         "userId":2,
        //         "message": "hi patrick",
        //         "time": "10.00",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 3,
        //         "userId":3,
        //         "message": "how's going on your project?",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 4,
        //         "userId":2,
        //         "message": "Do you need any help?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 33,
        //         "isToday": true
        //     },
        //     {
        //         "id": 5,
        //         "userId":1,
        //         "message": "Let me know?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 6,
        //         "userId":6,
        //         "message": "hi...Good Morning!",
        //         "time": "09:05",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     }],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // },
        // {
        //     "gourpId": 5,
        //     "name": "#Project-aplha",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 0,
        //     "isNew": true,
        //     "desc": "project related Group","messages":[{
        //         "id": 1,
        //         "userId":4,
        //         "message": "hi",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 2,
        //         "userId":2,
        //         "message": "hi patrick",
        //         "time": "10.00",
        //         "userType": "sender",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 3,
        //         "userId":3,
        //         "message": "how's going on your project?",
        //         "time": "01:05",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 4,
        //         "userId":2,
        //         "message": "Do you need any help?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     },
        //     {
        //         "id": 33,
        //         "isToday": true
        //     },
        //     {
        //         "id": 5,
        //         "userId":1,
        //         "message": "Let me know?",
        //         "time": "01:06",
        //         "userType": "receiver",
        //         "status": "2",
        //         "isImageMessage": false,
        //         "isFileMessage": false
        //     }],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // },
        // {
        //     "gourpId": 6,
        //     "name": "#Snacks",
        //     "profilePicture": "Null",
        //     "isGroup": true,
        //     "unRead": 0,
        //     "desc": "snacks Group",
        //     "messages":[],
        //     "members": [
        //         {
        //             "userId": 1,
        //             "name": "Sara Muller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 2,
        //             "name": "Ossie Wilson",
        //             "profilePicture": "/static/media/avatar-8.ad5418a90c29c2b98640.jpg",
        //             "role": "admin"
        //         },
        //         {
        //             "userId": 3,
        //             "name": "Jonathan Miller",
        //             "profilePicture": "Null",
        //             "role": null
        //         },
        //         {
        //             "userId": 4,
        //             "name": "Paul Haynes",
        //             "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 5,
        //             "name": "Yana sha",
        //             "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
        //             "role": null
        //         },
        //         {
        //             "userId": 6,
        //             "name": "Steve Walker",
        //             "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
        //             "role": null
        //         }
        //     ]
        // }
    ]
}

export var users = {
    "activeTab": "chat",
    "userSidebar": false,
    "conversationName": "Doris Brown",
    "layoutMode": "light",
    "recentChatList": [
        {
            "id": 0,
            "name": "Patrick Hendricks",
            "profilePicture": "/static/media/avatar-2.feb0f89de58f0ef9b424.jpg",
            "status": "online",
            "unRead": 0,
            "roomType": "contact",
            "isGroup": false,
            "messages": [
                {
                    "id": 1,
                    "message": "hi",
                    "time": "01:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "hi patrick",
                    "time": "10.00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 3,
                    "message": "how's going on your project?",
                    "time": "01:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "message": "Do you need any help?",
                    "time": "01:06",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 5,
                    "message": "Let me know?",
                    "time": "01:06",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 6,
                    "message": "hi...Good Morning!",
                    "time": "09:05",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 7,
                    "message": "image",
                    "time": "10:30",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
                        },
                        {
                            "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
                        }
                    ]
                },
                {
                    "id": 8,
                    "message": "please, save this pictures to your file and give it to me after you have done with editing!",
                    "time": "10:31",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 9,
                    "message": "okay sure😄👍",
                    "time": "02:50",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 1,
            "name": "Mark Messer",
            "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
            "status": "away",
            "unRead": 2,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hello",
                    "time": "10.00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "images",
                    "time": "10:30",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
                        },
                        {
                            "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
                        }
                    ]
                }
            ]
        },
        {
            "id": 13,
            "name": "General",
            "profilePicture": "Null",
            "unRead": 0,
            "isGroup": true,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "userName": "John Smith",
                    "message": "Hello send project images",
                    "time": "12:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "userName": "Steve Walker",
                    "message": "Images",
                    "time": "12:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
                        }
                    ]
                },
                {
                    "id": 3,
                    "userName": "steem.dapps",
                    "message": "Good Afternoon everyone !",
                    "time": "2:05",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "userName": "Galen Rizo",
                    "message": "This theme is Awesome!",
                    "time": "2:06",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 4,
            "name": "Doris Brown",
            "profilePicture": "/static/media/avatar-4.b23e41d9c09997efbc21.jpg",
            "status": "online",
            "unRead": 0,
            "isGroup": false,
            "isTyping": true,
            "messages": [
                {
                    "id": 1,
                    "userName": "Doris Brown",
                    "message": "Good Morning",
                    "time": "10:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "userName": "steem.dapps",
                    "message": "Good morning, How are you? What about our next meeting?",
                    "time": "10:02",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 3,
                    "message": "Yeah everything is fine",
                    "time": "10:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "message": "& Next meeting tomorrow 10.00AM",
                    "time": "10:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 5,
                    "message": "Wow that's great",
                    "time": "10:06",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 6,
                    "message": "images",
                    "time": "10:30",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
                        },
                        {
                            "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
                        }
                    ]
                },
                {
                    "id": 7,
                    "userName": "steem.dapps",
                    "message": "Files",
                    "time": "01:30",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": true,
                    "fileMessage": "admin_v1.0.zip",
                    "size": "12.5 MB"
                },
                {
                    "id": 8,
                    "message": "",
                    "time": "10:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false,
                    "isTyping": true
                }
            ]
        },
        {
            "id": 5,
            "name": "Designer",
            "profilePicture": "Null",
            "unRead": 1,
            "isGroup": true,
            "messages": [
                {
                    "id": 1,
                    "userName": "Doris Brown",
                    "message": "Hello send project images",
                    "time": "12:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 2,
                    "userName": "Steve Walker",
                    "message": "Images",
                    "time": "12:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
                        }
                    ]
                },
                {
                    "id": 3,
                    "userName": "steem.dapps",
                    "message": "Images",
                    "time": "01:30",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": true,
                    "fileMessage": "Minible-Vertical.zip"
                },
                {
                    "id": 4,
                    "userName": "steem.dapps",
                    "message": "@Doris Brown please review this code, and give me feedback asap",
                    "time": "01:31",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 5,
                    "userName": "John Howard",
                    "message": "Good Afternoon everyone !",
                    "time": "2:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 6,
                    "userName": "steem.dapps",
                    "message": "Good Afternoon everyone !",
                    "time": "2:05",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 9,
                    "userName": "John Howard",
                    "message": "Next meeting tomorrow 10.00AM",
                    "time": "2:10",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 6,
            "name": "Steve Walker",
            "profilePicture": "/static/media/avatar-6.dc44eabff29dbd9780cb.jpg",
            "status": "away",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "file",
                    "time": "01:16",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": true,
                    "fileMessage": "Minible-Vertical.zip"
                },
                {
                    "id": 2,
                    "message": "Okay 👍, let me check it and get back to you soon",
                    "time": "01:16",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 7,
            "name": "Albert Rodarte",
            "profilePicture": "Null",
            "status": "online",
            "unRead": 0,
            "isGroup": false,
            "isTyping": true,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hi",
                    "time": "01:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "Hello, how can i help you",
                    "time": "01:05",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 3,
                    "message": "",
                    "time": "01:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false,
                    "isTyping": true
                }
            ]
        },
        {
            "id": 8,
            "name": "Mirta George",
            "profilePicture": "Null",
            "status": "online",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hi...Good Morning!",
                    "time": "09:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "image",
                    "time": "10:30",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
                        },
                        {
                            "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
                        }
                    ]
                },
                {
                    "id": 3,
                    "message": "please, save this pictures to your file and give it to me after you have done with editing!",
                    "time": "10:31",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "message": "Yeah, Everything is fine👍",
                    "time": "02:50",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 9,
            "name": "Paul Haynes",
            "profilePicture": "/static/media/avatar-7.5ba5195e48f4c2c3c3fa.jpg",
            "status": "away",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hi...Good Morning!",
                    "time": "09:05",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "image",
                    "time": "10:30",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-4.c7a84ad5058b9382090e.jpg"
                        },
                        {
                            "image": "/static/media/img-7.c1d24290e482cebe87d1.jpg"
                        }
                    ]
                },
                {
                    "id": 3,
                    "message": "please, save this pictures to your file and give it to me after you have done with editing!",
                    "time": "10:31",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "message": "Good Morning😄",
                    "time": "02:50",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 2,
            "name": "Jonathan Miller",
            "profilePicture": "/static/media/avatar-2.feb0f89de58f0ef9b424.jpg",
            "status": "online",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hello Admin",
                    "time": "08:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "Good morning",
                    "time": "08:00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 3,
                    "message": "is everything is fine ?",
                    "time": "08:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 4,
                    "message": "i can help you😊",
                    "time": "08:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 5,
                    "message": "Hi, How are You?",
                    "time": "08:00",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        },
        {
            "id": 3,
            "name": "Ossie Wilson",
            "profilePicture": "/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg",
            "status": "away",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hi",
                    "time": "12:00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "Did you finished it?",
                    "time": "12:00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "I've finished it! See you so",
                    "time": "12:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
                        }
                    ]
                }
            ]
        },
        {
            "id": 14,
            "name": "Sara Muller",
            "profilePicture": "Null",
            "status": "offline",
            "unRead": 0,
            "isGroup": false,
            "messages": [
                {
                    "id": 33,
                    "isToday": true
                },
                {
                    "id": 1,
                    "message": "hi yana",
                    "time": "12:00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                },
                {
                    "id": 2,
                    "message": "image",
                    "time": "12:05",
                    "userType": "receiver",
                    "status": "2",
                    "isImageMessage": true,
                    "isFileMessage": false,
                    "imageMessage": [
                        {
                            "image": "/static/media/img-6.b0b6b01a87aeb7eee189.jpg"
                        }
                    ]
                },
                {
                    "id": 3,
                    "message": "Wow that's great",
                    "time": "12:00",
                    "userType": "sender",
                    "status": "2",
                    "isImageMessage": false,
                    "isFileMessage": false
                }
            ]
        }
    ]
};
