import React, { useState, useEffect, useRef } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import SimpleBar from "simplebar-react";

import withRouter from "../../../chat-components/withRouter";

//Import Components
// import UserProfileSidebar from "../../../chat-components/UserProfileSidebar";
// import SelectContact from "../../../chat-components/SelectContact";
import UserHead from "./UserHead";
import ImageList from "./ImageList";
import ChatInput from "./ChatInput";
import FileList from "./FileList";

//actions
// import { openUserSidebar, setFullUser } from "../../../redux/actions";
import { userChat as userChats, users } from "../../../GlobalVariables";
//Import Images
import avatar4 from "../../../assets/Images/users/avatar-4.jpg";
// import avatar1 from "../../../assets/Images/users/avatar-1.jpg";

//i18n
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "../../../StateManagement/AppContext";

import { BsCheck2, BsCheck2All } from "react-icons/bs";
import moment from "moment";
// import imageProfile from "../../../assets/Images/users/altImage.png";
import ProfileModal from "./ProfileModal";
import API from "../../../utils/API";
import InAppLoading from "../../../LayOut/InAppLoading";
import { BsFillReplyFill } from "react-icons/bs";

import SteemLogo from "../../../assets/Images/webIcon.png";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

let lastGroup = null;
let lastChat = null;

// import imageProfile from '../../../assets/Images/users/altImage.png';
function UserChat(props) {
  props = props.recentChatList;

  const ref = useRef();
  const { userData, setUserData, userDetails, socket, steemKeychain } =
    useContext(AppContext);
  const [modal, setModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentUser, setCurrentUser] = useState({});

  const [currentUserId, setCurrentUserId] = useState("");
  const [reply, setReply] = useState(null);

  const [showReplyBox, setShowReplyBox] = useState(false);
  const [profileImg, setprofileImg] = useState(
    userDetails.posting_json_metadata
      ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image
      : ""
  );
  const [personalMessages, setPersonalMessages] = useState(null);
  /* intilize t variable for multi language implementation */
  // const [isToday, setIsToday] = useState(false);
  const [ProfileModalOpen, setProfileModalOpen] = useState(false);
  const [ProfileModalLoading, setProfileModalLoading] = useState(false);
  const [ProfileDetails, setProfileDetails] = useState({});
  const [recentChatList, setRecentChatList] = useState(null);

  const [reFetch, setRefetch] = useState(true);
  const { t } = useTranslation();
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const mentionRegex = /\B(@\*[\w.-]+\*)/g;

  const combinedRegex = /(https?:\/\/[^\s]+)|(@?\*[\w.-]+\*)/g;
  const CRYPTO_SECRET_KEY = "thisOIsMyTestingPart"; // Replace with your secret key

  const encryptMessage = (message) => {
    try {
      return CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY)?.toString();
    } catch (error) {
      return null; // Handle error gracefully
    }
  };
  // const decryptMessage = (encryptedMessage) => {
  //   try {
  //     let bytes = CryptoJS.AES.decrypt(
  //       encryptedMessage,
  //       process.env.CRYPTO_SECRET_KEY
  //     );
  //     let decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  //     return decryptedData;
  //   } catch (error) {}
  // };
  const userName = localStorage?.getItem("userName");

  useEffect(() => {
    const getUserDetails = async () => {
      const userName = localStorage?.getItem("userName");
      const results = await API().GetUserdata(userName);
      setprofileImg(
        results.data.data.posting_json_metadata
          ? JSON.parse(results.data.data.posting_json_metadata).profile
              .profile_image
          : ""
      );
    };

    getUserDetails();
  }, []);

  const getUserChatList = () => {
    if (socket) {
      socket.emit("userRecentChatList", parseInt(localStorage?.getItem("id")));
      socket.on("userRecentChatData", (data) => {
        // console.log(
        //   "🚀 => user recent chat list data from socket:",
        //   data || "no data for user chat list"
        // );

        if (data) {
          setRecentChatList(data.chatList);
          // setUserData((prevState) => ({
          //   ...prevState,
          //   recentChatList: data.chatList,
          // }));
        }
      });
    }
  };

  useEffect(() => {
    getUserChatList();
  }, []);
  //demo conversation messages
  //userType must be required
  const [allUsers] = useState(
    JSON.stringify(userData)?.length === 2
      ? userChats.recentChatList
      : userData.recentChatList
  );
  const [chatMessages, setchatMessages] = useState([]);
  console.log("🚀 ~ chatMessages:", chatMessages)

  const [currentChat, setCurrentChat] = useState(null);
  const [chatLoading, setChatLoading] = useState(true);

  // ~ getting messages for community chats
  const getCommunityMessages = async () => {
    setChatLoading(true);
    const groupName = userData?.groups?.filter(
      (a) => a._id === userData.active_group
    )[0].name;
    lastGroup = groupName;
    if (groupName && userData) {
      // const res = await API().getCommunityMessages(
      //   groupName,
      //   userDetails.name
      //     ? userDetails.name
      //     : userDetails.posting_json_metadata
      //     ? JSON.parse(userDetails.posting_json_metadata).profile.name
      //     : ""
      // );
      let res;

      if (socket) {
        if (userData) {
          let Details = userData?.groups;
          const data = {
            groupName: Details?.filter(
              (a) => a.groupId === userData.active_group
            )[0]?.name,
            userName: userDetails?.name
              ? userDetails.name || localStorage?.getItem("userName")
              : userDetails.posting_json_metadata
              ? JSON.parse(userDetails.posting_json_metadata).profile?.name
              : "",
          };
          if (Details) {
            socket.emit("getCommunityMessage", data);
          }
          //   Details.filter(
          //     (a) => a.groupId === userData.active_group
          //   )[0].messages?.push(messageObj);
          //   setUserData((prevState) => ({ ...prevState, groups: Details }));
          // }
          // //  = chatMessages;
          // // setUserData(Details)
          // const messageToRoom = {
          //   ...messageObj,
          //   userType: "sender",
          // };
          // socket?.emit("sendMessage", {
          //   groupName: Details?.filter(
          //     (a) => a.groupId === userData.active_group
          //   )[0]?.name,
          //   messageToRoom,
          // });
        }

        socket.on("communityMessages", (data) => {
          res = data;
          // if (res?.data?.data?.length > 0) {
          if (data?.length > 0) {
            // console.log("🐱‍🏍 setting the details ");
            let Details = userData?.groups;
            if (Details) {
              Details.filter(
                (a) => a.groupId === userData.active_group
              )[0].messages = data;
              setchatMessages(data);
              setUserData((prevState) => ({ ...prevState, groups: Details }));
            }
          } else {
            setchatMessages(null);
          }
        });
      }
    }

    setChatLoading(false);
  };

  useEffect(() => {
    if (userData?.isGroup === true) {
      setCurrentGroup(
        userData?.groups?.filter((a) => a._id === userData.active_group)
      );
      setchatMessages(
        userData?.groups?.filter((a) => a._id === userData.active_group)[0]
          ?.messages
      );
      setCurrentGroupName(
        userData?.groups?.filter((a) => a._id === userData?.active_group)[0]
          ?.name
      );
    } else {
      // if (userData?.recentChatList) {
      if (recentChatList) {
        const activeUser = recentChatList?.find(
          (a) => a._id === userData.active_user
        );

        if (activeUser) {
          setCurrentUser(activeUser);
          // setCurrentUser(
          //   recentChatList?.filter((a) => a.id === userData.active_user)
          // );
        }

        // setchatMessages(
        //   recentChatList?.filter((a) => a.id === userData.active_user)[0]
        //     ?.messages
        // );

        setCurrentUserId(
          recentChatList?.filter((a) => a.id === userData?.active_user)[0]?._id
        );
        setCurrentGroupName("");
        lastGroup = null;
      }
      if (personalMessages) {
        setCurrentUser(
          recentChatList?.filter((a) => a._id === userData.active_user)
        );
        // setchatMessages(
        //   recentChatList?.filter((a) => a.id === userData.active_user)[0]
        //     ?.messages
        // );
        setchatMessages(personalMessages);

        setCurrentUserId(
          recentChatList?.filter((a) => a._id === userData?.active_user)[0]?._id
        );
        setCurrentGroupName("");
        lastGroup = null;
      }
    }
    // setchatMessages((userData?.isGroup == true?:));
    // console.log("chat call",userData,chatMessages);
    ref?.current?.recalculate();
    if (ref?.current?.el) {
      setTimeout(() => {
        // console.log(ref.current.getScrollElement().scrollTop,ref.current.getScrollElement().scrollHeight);
        ref.current.getScrollElement().scrollTop =
          ref.current.getScrollElement().scrollHeight;
      }, 200);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.isGroup !== true && lastChat !== currentUserId) {
      const chatId = recentChatList?.filter(
        (a) => a._id === userData?.active_user
      );
      const alt_user_id = recentChatList?.filter(
        (a) => a._id === userData?.active_user
      )[0]?.alt_user_id;
      if (chatId?.length > 0) {
        socket?.emit("resetUnreadCount", {
          groupName: chatId[0],
          alt_user_id: alt_user_id,
        });
        socket?.emit("getMessagePeer", {
          id: chatId,
          userName: userDetails?.name
            ? userDetails?.name
            : userDetails.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata).profile?.name
            : "",
        });
        lastChat = chatId;
      }
    }
  }, [currentUserId]);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (socket) {
      // if (currentUser) {
      setChatLoading(true);
      const chat = recentChatList?.find((item) => {
        return item?._id === currentUser[0]?._id;
      });

      socket.emit("fetchPersonalMessages", currentUser[0]?._id);
      socket.on("getPersonalMessages", (data) => {
        setPersonalMessages(data.messages || null);
      });
      setChatLoading(false);
      // }
    }
  }, [currentUser, reFetch, socket]);
  // * setting the current user
  useEffect(() => {
    if (recentChatList && !currentUser) {
      setCurrentUser(recentChatList[0]);
    }
  }, [recentChatList, currentUser]);

  // * setting the current chat or group id
  useEffect(() => {
    const chat = recentChatList?.find((item) => {
      return item?._id === currentUser[0]?._id;
    });
    setCurrentChat(chat);
  }, [currentUser, recentChatList]);

  // *-------------------------------------------------
  //******* */ sending the messages
  const addMessage = async (message, type, doc) => {
    const encryptedMessage = message ? message : "";

    let messageObj = null;
    // Matches the message type (text, file, or image) and creates an object accordingly
    switch (type) {
      case "textMessage":
        messageObj = {
          messageId: uuidv4(),
          id: chatMessages?.length + 1,
          message: encryptedMessage,
          time: moment(new Date()).format("DD/MM/YY hh:mm a"),
          userType: "sender",
          status: "2",
          userName: userDetails?.name
            ? userDetails?.name
            : userDetails?.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
            : userName,
          profilePicture: profileImg ? profileImg : "",
          isFileMessage: false,
          isImageMessage: false,
          isMentioned: true,
        };
        break;
      case "replyMessage":
        messageObj = {
          messageId: uuidv4(),
          id: chatMessages?.length + 1,
          message: encryptedMessage,
          time: moment(new Date()).format("DD/MM/YY hh:mm a"),
          userType: "sender",
          status: "2",
          userName: userDetails?.name
            ? userDetails?.name
            : userDetails?.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
            : userName || "Unknown User",
          profilePicture: profileImg ? profileImg : "",
          isFileMessage: false,
          isImageMessage: false,
          isReply: true,
          replydetails: doc,
        };
        break;
      case "fileMessage":
        messageObj = {
          messageId: uuidv4(),
          id: chatMessages?.length + 1,
          message: encryptedMessage,
          fileMessage: doc,
          size: "",
          time: moment(new Date()).format("DD/MM/YY hh:mm a"),
          userType: "sender",
          status: "2",
          userName: userDetails?.name
            ? userDetails?.name
            : userDetails?.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
            : userName || "Unknown User",
          profilePicture: profileImg ? profileImg : "",
          image: avatar4,
          isFileMessage: true,
          isImageMessage: false,
        };
        break;
      case "imageMessage":
        messageObj = {
          messageId: uuidv4(),
          id: chatMessages?.length + 1,
          message: encryptedMessage,
          imageMessage: [{ image: doc }],
          size: "11kb",
          time: moment(new Date()).format("DD/MM/YY hh:mm a"),
          userType: "sender",
          status: "2",
          userName: userDetails?.name
            ? userDetails?.name
            : userDetails?.posting_json_metadata
            ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
            : userName || "Unknown User",
          profilePicture: profileImg ? profileImg : "",
          image: avatar4,
          isImageMessage: true,
          isFileMessage: false,
        };
        break;
      default:
        break;
    }

    // Add the message object to the chat
    setchatMessages([...chatMessages, messageObj]);
    if (userData?.isGroup) {
      let Details = userData?.groups;
      if (Details) {
        Details.filter(
          (a) => a._id === userData.active_group
        )[0].messages?.push(messageObj);
        setUserData((prevState) => ({ ...prevState, groups: Details }));
      }
      const messageToRoom = {
        ...messageObj,
        userType: "sender",
      };
      socket?.emit("sendMessage", {
        groupName: Details?.filter((a) => a._id === userData.active_group)[0]
          ?.GroupName,
        messageToRoom,
      });
      const res = await API().addCommunityMessage({
        groupName: Details?.filter((a) => a._id === userData.active_group)[0]
          ?.GroupName,
        messageToRoom,
      });
    } else {
      let Details = recentChatList;
      const userName = Details?.filter(
        (a) => a._id === userData?.active_user
      )[0]?.user_detail[0]?.Name;

      Details?.filter((a) => a._id === userData?.active_user)[0].messages?.push(
        messageObj
      );
      setUserData((prevState) => ({ ...prevState, recentChatList: Details }));

      const messageToUser = {
        ...messageObj,
        message: messageObj.message ? encryptMessage(messageObj.message) : "",
        userType: "sender",
      };

      steemKeychain?.requestSignBuffer(
        userDetails?.name
          ? userDetails?.name || localStorage?.getItem("userName")
          : userDetails?.posting_json_metadata
          ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
          : userName || localStorage?.getItem("userName") || "Unknown User",
        JSON.stringify(messageToUser),
        "Posting",
        async function (response) {
          if (response.success) {
            steemKeychain.requestCustomJson(
              userDetails?.name
                ? userDetails?.name
                : userDetails?.posting_json_metadata
                ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
                : userName ||
                  localStorage?.getItem("userName") ||
                  "Unknown User",
              `steemchat.msg`,
              "Posting",
              JSON.stringify({
                from: userDetails?.name
                  ? userDetails?.name
                  : userDetails?.posting_json_metadata
                  ? JSON.parse(userDetails.posting_json_metadata)?.profile?.name
                  : userName ||
                    localStorage?.getItem("userName") ||
                    "Unknown User",
                to: userName,
                message: response.result,
                publicKey: response.publicKey,
              }),
              "# Private Message",
              function (broadcastResponse) {
                if (currentUser.length > 0) {
                  const chat = recentChatList?.find((item) => {
                    return item?._id === currentUser[0]?._id;
                  });
                  // setRefetch(!reFetch);

                  socket?.emit("sendMessage", {
                    groupName: chat?._id,
                    messageToUser,
                  });
                  socket?.emit("sendMessagePeer", {
                    groupName: chat?._id,
                    alt_user_id: chat?.user_detail[0]?._id,
                    messageToUser,
                  });
                  setTimeout(() => {
                    socket.emit("fetchPersonalMessages", currentUser[0]?._id);
                  }, 100); // 100ms delay
                  socket.on("getPersonalMessages", (data) => {
                    setPersonalMessages(data.messages || null);
                  });
                }
              }
            );
          } else {
            toast.warn("Sign Declined by user", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      );
    }

    setTimeout(() => {
      scrolltoBottom();
    }, 0);
  };

  function scrolltoBottom() {
    if (ref.current.el) {
      ref.current.getScrollElement().scrollTop =
        ref.current.getScrollElement().scrollHeight + 100;
    }
  }

  const openProfile = async (chat) => {
    try {
      setProfileModalLoading(true);
      const results = await API().GetUserdata(chat.userName);
      setProfileDetails({
        ...results.data.data,
        profile_image: results.data.data.posting_json_metadata
          ? JSON.parse(results.data.data.posting_json_metadata).profile
              .profile_image
          : "",
      });
      setProfileModalLoading(false);
      setProfileModalOpen(true);
    } catch (err) {
      console.log(err);
      setProfileModalLoading(false);
    }
  };

  const toggleModel = () => {
    setProfileModalOpen(!ProfileModalOpen);
  };
  const removeReply = () => {
    setReply(null);
  };

  //*  getting the personal messages

  useEffect(() => {
    if (userData?.isGroup === true && lastGroup !== currentGroupName) {
      getCommunityMessages();
    }
  }, [currentGroupName]);

  useEffect(() => {
    if (personalMessages) {
      setchatMessages(personalMessages);
    }
  }, [personalMessages]);

  // ~ if the loading is true
  // if (chatLoading) {
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{ height: "100vh" }}
  //     >
  //       <div className="spinner-border text-primary" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <div className="user-chat w-100 ">
        <InAppLoading isopened={ProfileModalLoading} />
        <ProfileModal
          isopened={ProfileModalOpen}
          toggleModel={toggleModel}
          details={ProfileDetails}
          centered
          scrollable
        />
        <div className="d-lg-flex" style={{ maxHeight: "100vh" }}>
          <div className={userData.userSidebar ? "w-70" : "w-100"}>
            {chatLoading ? (
              <div
                className="d-flex justify-content-center align-items-center h-100"
                // style={{ height: "100vh" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {!userData?.active_group &&
                userData?.active_user === undefined ? (
                  <div
                    className="d-flex justify-content-center align-items-center gap-2 flex-column"
                    style={{ height: "100vh" }}
                  >
                    <img
                      src={SteemLogo}
                      alt=""
                      height="100"
                      className="logo logo-dark"
                    />
                    <h5 className="pl-3 mt-3 font-size-18">
                      Welcome to SteemChat{" "}
                    </h5>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <i className="ri-lock-password-fill search-icon font-size-20 mt-2"></i>
                      <h5 className="pl-3 mt-3 font-size-14">
                        Your personal messages are end-to-end encrypted
                      </h5>
                    </div>
                  </div>
                ) : (
                  <>
                    <UserHead
                      recentChatList={
                        userData?.isGroup === true ? currentGroup : currentUser
                      }
                    />

                    <SimpleBar
                      // style={{ minHeight: "50vh" }}
                      ref={ref}
                      className="chat-conversation py-4 p-lg-4"
                      id="messages"
                    >
                      <ul className="list-unstyled py-3">
                        {chatMessages && chatMessages.length > 0 ? (
                          chatMessages.map((chat, key) => {
                            const isCurrentUser =
                              chat?._doc?.userName === userName;
                            const isToday = chat?._doc?.isToday === true;
                            const isGroup = userData?.isGroup === true;
                            const isTyping = chat?._doc?.isTyping;
                            const message = chat?.message;
                            const imageMessage = chat?.imageMessage;
                            const fileMessage = chat?._doc?.fileMessage;
                            const replyDetails = chat?._doc?.replydetails;
                            const profilePicture = chat?._doc?.profilePicture || chat?.profilePicture;
                            const time = chat?._doc?.createdAt;
                            const userType = chat?._doc?.userType;

                            return (
                              <React.Fragment key={key}>
                                {isToday && (
                                  <li key={"dayTitle" + key}>
                                    <div className="chat-day-title">
                                      <span className="title">Today</span>
                                    </div>
                                  </li>
                                )}
                                <li
                                  key={key}
                                  className={isCurrentUser ? "left" : "left"}
                                >
                                  <div className="conversation-list">
                                    {/* {!isCurrentUser && ( */}
                                    <div
                                      className="chat-avatar"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => openProfile(chat._doc)}
                                    >
                                      {profilePicture ? (
                                        <img
                                          src={profilePicture}
                                          alt="Profile"
                                        />
                                      ) : (
                                        <div className="chat-user-img align-self-center me-3">
                                          <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                              {chat?._doc?.userName?.length > 0
                                                ? chat._doc.userName.charAt(0)
                                                : "N/A"}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* )} */}
                                    <div className="user-chat-content">
                                      <div className="ctext-wrap">
                                        <div className="ctext-wrap-content px-2 py-1">
                                          {replyDetails?.length > 0 && (
                                            <>
                                              <p
                                                className="mb-0"
                                                style={{
                                                  fontWeight: "bold",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                Replied :
                                              </p>
                                              <p
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "flex-start",
                                                  marginBottom: 0,
                                                  borderLeft: "4px solid red",
                                                  borderRadius: 2,
                                                  opacity: "70%",
                                                  padding: 10,
                                                }}
                                              >
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    fontWeight: "600",
                                                    opacity: "100%",
                                                  }}
                                                >
                                                  {replyDetails[0]?.userName ||
                                                    ""}
                                                </p>{" "}
                                                : {replyDetails[0]?.message}
                                              </p>
                                            </>
                                          )}
                                          {imageMessage?.length > 0 && (
                                            <ImageList images={imageMessage} />
                                          )}
                                          {fileMessage && (
                                            <FileList
                                              fileName={fileMessage}
                                              fileSize={chat?._doc?.size}
                                            />
                                          )}
                                          {message && (
                                            <span className="mb-0">
                                              {message
                                                .split(combinedRegex)
                                                .map((part, index) => {
                                                  if (part?.match(urlRegex)) {
                                                    return (
                                                      <a
                                                        href={part}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            color: isCurrentUser
                                                              ? ""
                                                              : "#ffff",
                                                            textDecorationLine:
                                                              "underline",
                                                            maxWidth: "50vw",
                                                            wordWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          {part}
                                                        </p>
                                                      </a>
                                                    );
                                                  } else if (
                                                    part?.match(mentionRegex) &&
                                                    chat?._doc?.isMentioned
                                                  ) {
                                                    return (
                                                      <span
                                                        className="mb-0"
                                                        style={{
                                                          color: isCurrentUser
                                                            ? "#FFA500"
                                                            : "#FFFF00",
                                                          fontWeight: "bold",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          openProfile({
                                                            userName: part
                                                              ?.replaceAll(
                                                                "*",
                                                                ""
                                                              )
                                                              .split("@")[1],
                                                          })
                                                        }
                                                      >
                                                        {part?.replaceAll(
                                                          "*",
                                                          ""
                                                        )}
                                                      </span>
                                                    );
                                                  } else {
                                                    return part;
                                                  }
                                                })}
                                            </span>
                                          )}

                                          {isTyping && (
                                            <p className="mb-0">
                                              typing
                                              <span className="animate-typing">
                                                <span className="dot ms-1"></span>
                                                <span className="dot ms-1"></span>
                                                <span className="dot ms-1"></span>
                                              </span>
                                            </p>
                                          )}
                                          {!isTyping && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: isCurrentUser
                                                  ? "flex-end"
                                                  : "flex-start",
                                                gap: 4,
                                              }}
                                            >
                                              <p
                                                className="chat-time mb-0"
                                                style={{
                                                  textAlignLast: "justify",
                                                }}
                                              >
                                                <span
                                                  className="align-middle"
                                                  style={{ width: "20px" }}
                                                >
                                                  {moment(time).format("lll")}
                                                </span>
                                              </p>
                                              {isCurrentUser &&
                                                (chat?._doc?.isConnected ===
                                                "online" ? (
                                                  <BsCheck2
                                                    color="gray"
                                                    fontSize={15}
                                                  />
                                                ) : (
                                                  <BsCheck2All
                                                    color={
                                                      chat._doc?.status ===
                                                      "offline"
                                                        ? "gray"
                                                        : "#0652c4"
                                                    }
                                                    fontSize={15}
                                                  />
                                                ))}
                                            </div>
                                          )}
                                        </div>
                                        {!isTyping && (
                                          <UncontrolledDropdown className="align-self-start">
                                            <DropdownToggle tag="a">
                                              <i className="ri-more-2-fill"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={() => {
                                                  setReply(chat);
                                                  setShowReplyBox(true);
                                                }}
                                              >
                                                {t("Reply")}{" "}
                                                <BsFillReplyFill className="float-end text-muted mt-1" />
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        )}
                                        {/* {time} */}
                                      </div>
                                      {!isGroup && (
                                        <div
                                          className="conversation-name"
                                          style={{ color: "#7269ef" }}
                                        >
                                          {chat?._doc?.userName}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <div className="w-full h-auto flex justify-content-center align-items-center text-center">
                            Start Your Conversation
                          </div>
                        )}
                      </ul>
                    </SimpleBar>

                    <ChatInput
                      onaddMessage={addMessage}
                      chatMessages={chatMessages}
                      reply={reply}
                      // need the send the reply as an array
                      // as tge messages
                      removeReply={removeReply}
                      showReplyBox={showReplyBox}
                    />
                  </>
                )}
              </>
            )}
            {/* <Modal backdrop="static" isOpen={modal} centered toggle={toggle}>
                            <ModalHeader toggle={toggle}>Forward to...</ModalHeader>
                            <ModalBody>
                                <CardBody className="p-2">
                                    <SimpleBar style={{ maxHeight: "200px" }}>
                                        <SelectContact handleCheck={() => { }} />
                                    </SimpleBar>
                                    <ModalFooter className="border-0">
                                        <Button color="primary">Forward</Button>
                                    </ModalFooter>
                                </CardBody>
                            </ModalBody>
                        </Modal> */}
          </div>
          {/* <UserProfileSidebar activeUser={userChats.recentChatList[userChats.active_user]} /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { active_user } = state.Chat;
  const { userSidebar } = state.Layout;
  return { active_user, userSidebar };
};

export default withRouter(UserChat);
// export default withRouter(connect(mapStateToProps, { openUserSidebar, setFullUser })(UserChat));
