import React, { useEffect, useState } from "react";
import { withSteem } from "react-steemlogin";
import { Link, useNavigate } from "react-router-dom";

// Import Components
import ChatLeftSidebar from "./ChatLeftSidebar";
import UserChat from "./UserChat";
import LeftSidebarMenu from "../../LayOut/LeftSidebarMenu";
import { userChat, users } from "../../GlobalVariables";
import { useContext } from "react";
import { AppContext } from "../../StateManagement/AppContext";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllAdminPost } from "../../utils/API";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

let serverstart = 0;
const DashBoard = withSteem(({ steem }) => {
  const navigate = useNavigate();
  const { userDetails, setUserDetails, setSteemKeychain } =
    useContext(AppContext);
  const { loginUrl, auth, loading } = steem;
  useEffect(() => {
    if (!auth && !userDetails) {
      navigate("/");
    }
  }, [auth, userDetails]);

  //~ checking the user logged in while reloading the page
  // useEffect(() => {
  //   const checkAuthStatus = async () => {
  //     // Wait for auth and userDetails to be checked
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     if (!auth && !userDetails) {
  //       navigate("/");
  //     }
  //     setIsLoading(false);
  //   };

  //   checkAuthStatus();
  // }, [auth, userDetails]);

  const user = users || [];
  let userCha = userChat || [];
  userCha["user"] = user;
  const { userData, setUserData } = useContext(AppContext);
  const [sendData, setsendData] = useState(userData || userCha);
  const [postsData, setPostData] = useState([]);
  // console.log("🐱‍👤✨ ~ DashBoard ~ postsData:", postsData);

  // ~ getting te single post data from the link
  const getSinglePostDataFromLink = async (post, idx, postLink) => {
    function extractUsername(postLink) {
      // Use regex to match the username pattern in the URL
      const match = postLink.match(/@([a-zA-Z0-9_-]+)/);

      // If a match is found, return the username without '@'
      return match ? match[1] : null;
    }

    const username = extractUsername(post.postUrl);

    try {
      const response = await axios.get(postLink);


      if (response.status === 200) {
        // ~ getting the author profile picture
        const profileResponse = await axios.get(
          `https://sds0.steemworld.org/accounts_api/getAccountsExt/${username}`
          // `https://sds.steemworld.org/accounts_api/getAccount/${response.data?.result?.author}`
        );

        if (profileResponse.status === 200) {
          try {
            // Extract the posting_json_metadata field
            let profileData = profileResponse.data?.result?.rows?.[0]?.[7]; // Accessing `posting_json_metadata` based on index.
        
            if (profileData) {
              // Parse the JSON string
              profileData = JSON.parse(profileData);
              profileData = profileData?.profile;
        
              // Extract the profile image URL
              const profilePicture = profileData?.profile_image;
        
              if (profilePicture) {
                // Add profile picture to the post data
                const updatedPostData = { ...post, ...response.data, profilePicture };
        
                // Update the postsData state
                setPostData((prevData) => {
                  const newData = [...prevData];
                  newData[idx] = updatedPostData;
                  return newData;
                });
        

              } else {
                console.error("Profile image not found in profile data.");
              }
            } else {
              console.error("Posting JSON metadata is empty or undefined.");
            }
          } catch (error) {
            console.error("Error parsing profile data:", error);
          }
        } else {
          console.error(`Failed to fetch profile data. Status: ${profileResponse.status}`);
        }
        
      } else {
        console.log("🐱‍👤✨ ~ No data found for the post");
      }
    } catch (error) {}
  };

  // ~ getting admin post data
  const handleGetAdminPost = async () => {
    try {
      // setIsLoading(true);
      const res = await getAllAdminPost();
      if (res.success) {
        res.posts.forEach((post, idx) => {
          const { postName, name } = post;

          if (postName) {
            const authorNameAndPost = `${name}/${postName}`;
            // const postLink = `https://sds0.steemworld.org/accounts_api/getAccountsExt/${authorNameAndPost}`;
            const postLink = `https://sds.steemworld.org/posts_api/getPost/${authorNameAndPost}`;

            getSinglePostDataFromLink(post, idx, postLink);
          } else {
            console.warn(
              `Post at index ${idx} does not have a valid postName.`
            );
          }
        });
        setPostData(res.posts);
      } else {
        setPostData([]);
        console.error("Failed to fetch admin posts.");
      }
    } catch (error) {
      console.error("Error fetching admin posts:", error);
      setPostData([]);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetAdminPost();
  }, []);

  useEffect(() => {
    // setUserData((prevState) => ({ ...prevState, ...userCha }));
    // setsendData(userChat || []);
    if (window.steem_keychain) {
      const steem_keychain = window.steem_keychain;
      setSteemKeychain(steem_keychain);
    }
    if (!auth && JSON.stringify(userDetails) === "{}") {
      navigate("/");
    }
  }, []);

  function calculateTimeDifference(timestamp) {
    const now = new Date();
    const postDate = new Date(timestamp);
    const diffInMilliseconds = now - postDate;

    const seconds = Math.floor(diffInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  }

  return (
    <React.Fragment>
      <div className="layout-wrapper w-100 d-lg-flex">
        {/* sidebar Menu */}
        <LeftSidebarMenu props={userData} />

        {/* Chat left sidebar */}
        <ChatLeftSidebar userChat={userData} />

        {/* User chat */}

        <div
          className="w-75 d-flex flex-column"
          style={{
            backgroundColor: "none",
          }}
        >
          <div
            className="d-flex flex-column w-100"
            style={{ maxHeight: "164px", overflowX: "hidden" }}
          >
            {postsData.length === 0 ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
              >
                <p>No Posts Found!</p>
              </div>
            ) : (
              <Marquee
                gradient={false}
                speed={50}
                pauseOnHover={true}
                className="mt-2"
                // loop={3}
              >
                {postsData?.map((item, idx) => (
                  <Link to={item?.postUrl} target="_blank" key={idx}>
                    <div className="d-flex flex-row gap-2 align-items-center px-1">
                      <div
                        className="d-flex flex-column p-2 position-relative rounded-2 border-1"
                        style={{
                          backgroundImage: `url(${item?.bannerImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          minWidth: "240px",
                          minHeight: "133px",
                          overflow: "hidden",
                        }}
                      >
                        {/* <div
                          className="d-flex justify-content-center align-items-center gap-1 rounded-4 position-absolute py-1 px-2"
                          style={{
                            top: "4px",
                            right: "4px",
                            fontSize: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                          }}
                        >
                          <span>33</span>
                          <FaEye />
                        </div> */}

                        <div
                          className="d-flex flex-column rounded-2 position-absolute w-100 py-1 px-2"
                          style={{
                            bottom: "0px",
                            left: "0px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          <h6
                            className="text-white"
                            style={{
                              fontSize: "13px",
                              textOverflow: "ellipsis",
                              whiteSpace: "wrap",
                            }}
                          >
                            {item?.postName
                              .split("-")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </h6>

                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <img
                              src={
                                item?.profilePicture ||
                                "https://steemitimages.com/p/default-profile"
                              }
                              alt="dp"
                              style={{
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <li
                              style={{
                                color: "white",
                                fontSize: "12px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                listStyle: "none",
                              }}
                            >
                              @{item?.result?.author || item?.name}
                            </li>

                            {/* <li
                              style={{
                                color: "white",
                                fontSize: "10px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item?.result?.created
                                ? calculateTimeDifference(
                                    parseInt(item?.result?.created) * 1000
                                  )
                                : "unknown"}
                            </li> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Marquee>
            )}
          </div>

          <UserChat recentChatList={userData} />
        </div>
      </div>
    </React.Fragment>
  );
});

export default DashBoard;
