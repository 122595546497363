import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Row, Col, UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu, Label, Form } from "reactstrap";
import EmojiPicker from 'emoji-picker-react';
import { AppContext } from '../../../StateManagement/AppContext';
import imageProfile from '../../../assets/Images/users/altImage.png';
import { Mention, MentionsInput } from "react-mentions";

function ChatInput(props) {
    const { userData, userDetails, socket } = useContext(AppContext);
    const [profileImg, setprofileImg] = useState(userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.profile_image : '');
    const [textMessage, settextMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [isOpen, setisOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [file, setfile] = useState({
        name: "",
        size: ""
    });
    const [fileImage, setfileImage] = useState("")

    const toggle = () => setisOpen(!isOpen);

    //function for text input value change
    const handleChange = e => {
        settextMessage(e.target.value)
        if (!isTyping) {
            userTyping()
            setIsTyping(true)
        }
    }

    const onEmojiClick = (event, emojiObject) => {
        // console.log(emojiObject,event);
        settextMessage(textMessage + event.emoji)
    };

    //function for file input change
    const handleFileChange = e => {
        if (e.target.files.length !== 0)
            setfile({
                name: e.target.files[0].name,
                size: e.target.files[0].size
            })
    }

    //function for image input change
    const handleImageChange = e => {
        if (e.target.files.length !== 0)
            setfileImage(URL.createObjectURL(e.target.files[0]))
    }

    //function for send data to onaddMessage function(in userChat/index.js component)
    const onaddMessage = (e, textMessage) => {
        e.preventDefault();
        //if text value is not emptry then call onaddMessage function
        if (textMessage !== "") {
            if (props.reply) {
                props.onaddMessage(textMessage, "replyMessage", props.reply);
                settextMessage("");
                props.removeReply()
            } else {
                props.onaddMessage(textMessage, "textMessage");
                settextMessage("");
            }
        }


        //if file input value is not empty then call onaddMessage function
        if (file.name !== "") {
            props.onaddMessage(file, "fileMessage");
            setfile({
                name: "",
                size: ""
            })
        }

        //if image input value is not empty then call onaddMessage function
        if (fileImage !== "") {
            props.onaddMessage(textMessage, "imageMessage", fileImage);
            setfileImage("")
        }
    }

    const userTyping = () => {
        try {
            if (userData) {
                let Details = userData
                const messageToRoom = {
                    id: props.chatMessages.length + 10,
                    message: '',
                    userType: "receiver",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    isFileMessage: false,
                    isImageMessage: false,
                    isTyping: true
                };
                socket?.emit("userTyping", {
                    groupName: Details.groups.filter(
                        (a) => a.groupId == userData.active_group
                    )[0].name, messageToRoom
                })
            }
        } catch (e) { }
    }

    const userTypingStopped = () => {
        try {
            if (userData) {
                let Details = userData
                const messageToRoom = {
                    id: props.chatMessages.length + 10,
                    message: '',
                    userType: "receiver",
                    status: "2",
                    userName: userDetails.name ? userDetails.name : userDetails.posting_json_metadata ? JSON.parse(userDetails.posting_json_metadata).profile.name : 'Unknown User',
                    profilePicture: profileImg ? profileImg : imageProfile,
                    isFileMessage: false,
                    isImageMessage: false,
                    isTyping: true,
                    isTypingStopped: true
                };
                socket?.emit("userTyping", {
                    groupName: Details.groups.filter(
                        (a) => a.groupId == userData.active_group
                    )[0].name, messageToRoom
                })
            }

            setIsTyping(false)
        } catch (e) { }
    }

    useEffect(() => {
        const delayFn = setTimeout(() => userTypingStopped(), 500);

        return () => clearTimeout(delayFn);
    }, [textMessage]);


    const usersData = (query) => {
        // console.log(query)
        return userData?.groups?.filter(e => {
            return e.groupId === userData?.active_group
        })[0]?.userRoles?.filter(element => { return element?.id.startsWith(query) }).slice(0, 10)
    }
    // useEffect(() => {
    //     if (userData && userData?.active_group) {
    //         setUsers(userData?.groups?.filter(e => {
    //             return e.groupId === userData?.active_group
    //         })[0]?.userRoles)
    //         // console.log(
    //         //     userData?.groups?.filter(e => {
    //         //         return e.groupId === userData?.active_group
    //         //     })[0].userRoles
    //         // )
    //     }
    // }, [props]);


    return (
        <React.Fragment>
            <div className="p-1 p-lg-2 border-top mb-0">
                <Form onSubmit={(e) => onaddMessage(e, textMessage)} >
                    <Row className='g-0'>
                        {fileImage && (
                            <Row className='image-selected' style={{ position: 'fixed', bottom: 62, width: '70%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Col xs={11}>
                                    <img src={fileImage} alt='' style={{ height: '40px', width: '40px' }} />
                                    {`       `}Image Selected
                                </Col>
                                <Col xs={1} style={{ cursor: 'pointer' }}
                                    onClick={() => { setfileImage('') }}>
                                    X
                                </Col>
                            </Row>
                        )}
                        {props.reply && (
                            <Row className='image-selected' style={{ position: 'fixed', bottom: 62, width: '70%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Col xs={11}>
                                    <p className="mb-0" style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                        Reply :
                                    </p>
                                    <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 0, alignSelf: 'flex-start', borderLeft: '4px solid red', borderRadius: 2, opacity: '70%', padding: 10 }}>
                                        {props.reply.userName} : {props.reply.message}
                                    </p>
                                </Col>
                                <Col xs={1} style={{ cursor: 'pointer' }}
                                    onClick={() => { props.removeReply() }}>
                                    X
                                </Col>
                            </Row>
                        )}
                        <Col>
                            <div>
                                <MentionsInput type="text"
                                    classNames={{
                                        mentions__input: 'form-control',
                                        mentions__suggestions__item: 'form-control rounded-0'
                                    }}
                                    singleLine
                                    forceSuggestionsAboveCursor={true}
                                    value={textMessage}
                                    onChange={handleChange}
                                    placeholder="Enter Message...">
                                    <Mention data={usersData} appendSpaceOnAdd
                                        markup="@*__display__*"
                                        // onAdd={(id, display, startPos, endPos) => console.log(id, display, startPos, endPos)}
                                        displayTransform={(id, display) => `@${display}`} />
                                </MentionsInput>
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div className="chat-input-links ms-md-2">
                                <ul className="list-inline mb-0 ms-0">
                                    {/* <EmojiPicker height={500} width={400} /> */}
                                    {/* <EmojiPicker onEmojiClick={onEmojiClick}  /> */}
                                    <li className="list-inline-item">
                                        <ButtonDropdown className="emoji-dropdown" direction="up" isOpen={isOpen} toggle={toggle}>
                                            <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-emotion-happy-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <EmojiPicker onEmojiClick={onEmojiClick} />
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                        <UncontrolledTooltip target="emoji" placement="top">
                                            Emoji
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="list-inline-item input-file">
                                        <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                            <i className="ri-attachment-line"></i>
                                            <Input onChange={(e) => handleFileChange(e)} type="file" name="fileInput" size="60" />
                                        </Label>
                                        <UncontrolledTooltip target="files" placement="top">
                                            Attached File
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="list-inline-item input-file">
                                        <Label id="images" className="me-1 btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                            <i className="ri-image-fill"></i>
                                            <Input onChange={(e) => handleImageChange(e)} accept="image/*" type="file" name="fileInput" size="60" />
                                        </Label>
                                        <UncontrolledTooltip target="images" placement="top">
                                            Images
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="list-inline-item">
                                        <Button type="submit" color="primary" className="font-size-16 btn-lg chat-send waves-effect waves-light">
                                            <i className="ri-send-plane-2-fill"></i>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
}

export default ChatInput;