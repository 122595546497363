import axios from "axios";
const Api = process.env.REACT_APP_API_URL; // SYSTEM ACCESS IP
const BaseUrl = process.env.REACT_APP_BACKEND_URL;
// const BaseUrl = "http://localhost:3001/api";
const steemApiUrl = "https://sds.steemworld.org";
const fetchJSONwithouttoken = async ({ url }) => {
  try {
  } catch (error) {
    console.log("error in fetiching json wihtout token :::");
  }
  const res = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return axios.get(url);
};
const fetchJSONPOSTwithbodywithouttoken = async ({ url, body }) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return axios.post(url, JSON.stringify(body), axiosConfig);
};

const API = (credentials) => ({
  // ############################
  // ######## Get Apies #########
  // ############################

  getUsersDetails: () =>
    fetchJSONwithouttoken({
      url: `${Api}/getUsersDetails`,
    }),
  GetUserCommunitites: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunitiesBySubscriber/${userName}`,
    }),
  GetUserCommunititesRoles: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunityRoles/${userName}`,
    }),
  GetUserCommunititesSubscribers: (userName) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunitySubscribers/${userName}`,
    }),
  GetUserdata: (userName) =>
    fetchJSONwithouttoken({
      url: `${Api}/getSteemAccount?name=${userName}`,
    }),
  searchUsers: (userName) =>
    fetchJSONwithouttoken({
      url: `${Api}/searchUsers?name=${userName}`,
    }),
  GetCommunititesDetails: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunity/${name}`,
    }),
  GetProfileDetails: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/accounts_api/getAccount/${name}`,
    }),
  GetProfileDelegatorsIncoming: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/delegations_api/getIncomingDelegations/${name}`,
    }),
  GetProfileDelegatorsOutGoing: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/delegations_api/getOutgoingDelegations/${name}`,
    }),
  GetCommunityByName: (name) =>
    fetchJSONwithouttoken({
      url: `${steemApiUrl}/communities_api/getCommunity/${name}`,
    }),
  GetCommunititesData: (id) =>
    fetchJSONwithouttoken({
      url: `${Api}/GetCommunititesData?userId=${id}`,
    }),
  getUserChatList: (id) =>
    fetchJSONwithouttoken({
      url: `${Api}/getUserChatList?userId=${id}`,
    }),
  getCommunityMessages: (_groupName, _userName) =>
    fetchJSONwithouttoken({
      url: `${Api}/GetCommunitiesMessage?groupName=${_groupName}&userName=${_userName}`,
    }),
  // ############################
  // ######## Post Apies ########
  // ############################

  UpdateUser: (_details) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/UpdateUser`,
      body: {
        UserDetails: _details,
      },
    }),
  UpdateCommunities: (_details, _userid) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/UpdateCommunities`,
      body: {
        Details: _details,
        userId: _userid,
      },
    }),
  addUserChat: (_details, _userId, _chatWithUserID) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/addUserChat`,
      body: {
        Details: _details,
        userId: _userId,
        altUserId: _chatWithUserID,
      },
    }),
  addCommunityMessage: (message) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/AddCommunitiesMessage`,
      body: {
        message: message,
      },
    }),
  addPersonalMessage: (message) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/AddPersonalMessage`,
      body: {
        message: message,
      },
    }),
  deleteChat: (data) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/deleteChat`,
      body: {
        chat_id: data,
      },
    }),
});

// ~ get week registered users
export const getAllAdminPost = async () => {
  const token = localStorage?.getItem("token") || "";
  // console.log("🐱‍👤✨ ~ refreshToken ~ token:", token);
  try {
    const response = await axios.get(`${BaseUrl}/get-admin-posts`, {
      headers: {
        token: token,
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.message;
  }
};

export default API;
