import React, { useEffect, useState } from 'react';
import { withSteem } from "react-steemlogin";
import { useNavigate } from "react-router-dom";

// Import Components
import ChatLeftSidebar from './ChatLeftSidebar';
import UserChat from './UserChat';
import LeftSidebarMenu from '../../LayOut/LeftSidebarMenu';
import { userChat, users } from '../../GlobalVariables';
import { useContext } from 'react';
import { AppContext } from '../../StateManagement/AppContext';
let serverstart = 0;
const DashBoard = withSteem(({ steem }) => {
    const navigate = useNavigate();
    const { userDetails, setUserDetails, socket } = useContext(AppContext);
    const { loginUrl, auth, loading } = steem;
    useEffect(() => {
        if (!auth && !userDetails) {
            navigate("/");
        }
    }, [auth, userDetails]);
    const user = users || [];
    let userCha = userChat || [];
    userCha["user"] = user;
    const { userData, setUserData } = useContext(AppContext);
    const [sendData, setsendData] = useState(userCha || userData);
    useEffect(() => {
        setUserData((prevState) => ({ ...prevState, userCha }));
        setsendData(userChat || []);
        if (!auth && JSON.stringify(userDetails) == "{}") {
            navigate("/");
        }
    }, [])
    useEffect(() => {
        setsendData(userData || []);
    }, [userData])
    useEffect(() => {
        if (socket) {
            socket.on('LiveMessages', (data) => {
                if (userData?.groups) {
                    let Details = userData.groups
                    let messageArr = []
                    const typingMessages = Details.filter(
                        (a) => a.name == data.groupName
                    )[0].messages?.filter(ee => ee.isTyping)
                    const textMessages = Details.filter(
                        (a) => a.name == data.groupName
                    )[0].messages?.filter(ee => !ee.isTyping)
                    console.log(textMessages, 'aaaaaaaaaaaa', typingMessages)
                    messageArr = [...textMessages, data?.messageToRoom, ...typingMessages]
                    Details.filter(
                        (a) => a.name == data.groupName
                    )[0].messages = messageArr;
                    setUserData(prevState => ({ ...prevState, groups: Details }))
                }
            })
            socket.on('isUserTyping', (data) => {
                if (data.messageToRoom.isTypingStopped) {
                    if (userData?.groups) {
                        let Details = userData.groups
                        Details.filter(
                            (a) => a.name == data.groupName
                        )[0].messages = Details.filter(
                            (a) => a.name == data.groupName
                        )[0].messages.filter((e) => { return e.userName == data?.messageToRoom?.userName && e?.isTyping !== true })
                        setUserData(prevState => ({
                            ...prevState, groups: Details
                        }))
                    }
                } else {
                    if (userData?.groups) {
                        let Details = userData.groups
                        Details.filter(
                            (a) => a.name == data.groupName
                        )[0].messages.push(data?.messageToRoom);
                        setUserData(prevState => ({ ...prevState, groups: Details }))
                    }
                }
            })
            return () => { socket.off("LiveMessages"); socket.off("isUserTyping"); };
        }
    }, [socket, userData]);
    return (
        <React.Fragment>
            <div className='layout-wrapper d-lg-flex'>
                {/* sidebar Menu */}
                <LeftSidebarMenu props={sendData} />

                {/* Chat left sidebar */}
                <ChatLeftSidebar userChat={sendData} />

                {/* User chat */}
                <UserChat recentChatList={sendData} />
            </div>
        </React.Fragment>
    );
});



export default DashBoard;
